import React, {useEffect, useState} from 'react';
import {SurveyCreatorComponent, SurveyCreator} from "survey-creator-react";
import {useNotifications} from "./contexts/ContextNotification";
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router";
import {
  ArrowUturnLeftIcon,
  ChevronLeftIcon,
  CogIcon,
  EyeIcon, LinkIcon,
} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {fetchApi} from "./utils/fetch";
import {Spinner} from "./Spinner";
import {classNames} from "./utils/classNames";
import ModalQuizSettings from "./ModalQuizSettings";
import {ReactElementFactory} from "survey-react-ui";
import AceEditor from "react-ace";
import QuizFunctionsEditor from "./QuizFunctionsEditor";
import {NOTIFICATION_TYPES} from "./Notifications";
import { Serializer } from "survey-core";

import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.fontless.min.css";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/ext-language_tools";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import './QuizEditor.css';
import Tippy from "@tippyjs/react";

Serializer.addProperty('survey', {
  name: "randomizePages",
  category: "pages",
  visibleIndex: 1,
  type: 'boolean',
  displayName: 'Randomize pages',
  visible: true,
});

Serializer.addProperty('page', {
  name: "excludeFromRandom",
  category: "general",
  visibleIndex: 1,
  type: 'boolean',
  displayName: 'Exclude from random [Keep current position]',
  visible: true,
});

export default function QuizEditor() {
  const [creator, setCreator] = useState(null);
  const [saving, setSaving] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const {addNotification} = useNotifications()
  const {
    quiz: initQuiz,
    read_only: readOnly,
    draft,
    test_token_values: tokenValues,
    required_formulas: requiredFormulas
  } = useLoaderData();
  const {quizId} = useParams();
  const {state: locationState} = useLocation();
  const [showFormulaEditor, setShowFormulaEditor] = useState(false);
  const [showJSONEditor, setShowJSONEditor] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [formulaHasError, setFormulaHasError] = useState(false);

  useEffect(() => {
    if (locationState?.notificationState) addNotification(locationState.notificationState);
  }, [locationState]);

  useEffect(() => {
    if (draft) {
      hideExternalEditors();
      return navigate(`/quizzes/${draft.id}`);
    }

    setShow(true);

    const creator = new SurveyCreator({
      haveCommercialLicense: true,
      showTranslationTab: true,
      isAutoSave: true,
      readOnly,
    });

    ReactElementFactory.Instance.registerElement(
      "formula-creator",
      (props) => {
        // We have to render something here so we can get the active/deactivate callbacks
        return React.createElement(QuizFunctionsEditor, {
          ...props,
          initQuiz,
          requiredFormulas,
          quizId: initQuiz?.id,
          tokenValues: tokenValues,
          save: (formulas, tokenValues) => {
            save(creator, undefined, undefined, formulas, tokenValues);
          },
          onFormulaError: (hasError) => setFormulaHasError(hasError),
        });
      }
    );

    creator.addPluginTab("formula-creator", {
      activate: () => { setShowFormulaEditor(true) },
      deactivate: () => {
        setShowFormulaEditor(false);
        return true;
      }
    }, "Formulas", "formula-creator", 3);

    creator.text = initQuiz.quiz ? JSON.stringify(initQuiz.quiz) : null;

    creator.saveSurveyFunc = (saveNo, callback) => save(creator, saveNo, callback);

    setCreator(creator);
  }, [draft, quizId]);

  function hideExternalEditors() {
    setShowJSONEditor(false);
    setShowFormulaEditor(false);
  }

  function save(creator, saveNo, callback, formulas, tokenValues) {
    if (creator.readOnly) return;

    setSaving(true);

    const body = {
      parent_id: initQuiz.id,
      save_number: saveNo,
    };

    if (tokenValues) body.token_values = tokenValues;
    if (formulas) body.formulas = formulas;
    if (creator.JSON) body.updated_quiz = creator.JSON;

    fetchApi(`/api/v1/internal/quizzes/${quizId ? quizId : ''}`, {
      method: quizId ? 'PATCH' : 'POST',
      body: JSON.stringify(body),
    }).then((resp) => {
      const {error} = resp;
      if (error) addNotification({ title: 'Cannot make change', body: error, type: NOTIFICATION_TYPES.FAILURE });
      if (!!callback) callback(saveNo, resp.success);
      if (!quizId) navigate(`/quizzes/${resp.quiz.id}`);
      if (resp.draft) {
        hideExternalEditors();
        navigate(`/quizzes/${resp.draft.id}`);
      }
    }).finally(() => setSaving(false));
  }

  function rollback() {
    if (initQuiz.is_published) {
      return window.alert('This quiz is already published and cannot be rolled back.');
    }

    if (!window.confirm('Are you sure you want to revert all your changes to the last published version of this quiz?')) {
      return;
    }

    fetchApi(`/api/v1/internal/quizzes/${quizId}`, {method: 'DELETE'})
    .then(({success, quiz, error}) => {
      if (success) {
        navigate(`/quizzes/${quiz.id}`, {
          state: {
            notificationState: {
              title: 'Quiz rolled back',
              body: `This quiz has been rolled back to the previously published version.`,
            }
          }
        });
      } else {
        addNotification({title: 'Could not rollback', body: error, type: NOTIFICATION_TYPES.FAILURE})
      }
    });
  }

  function updateQuizSettings({isAvailable, surveyType, isInternal, isCoachabilityQuiz, description}) {
    fetchApi(`/api/v1/internal/quizzes/${quizId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        is_available: isAvailable,
        is_coachability: isCoachabilityQuiz,
        is_internal: isInternal,
        survey_type: surveyType,
        description,
      })
    }).then(({success}) => {
      if (success) {
        navigate(`/quizzes/${quizId}`, {
          state: {
            notificationState: {
              title: 'Quiz Settings updated',
              body: 'Quiz settings are up to date.'
            }
          }
        });
      }
    });
  }

  function publish() {
    if (!window.confirm(`Are you sure you want to publish this version?`)) return;

    fetchApi(`/api/v1/internal/quizzes/${quizId}/publish`, {
      method: 'POST',
      body: JSON.stringify({
        confirm: true,
      })
    })
    .then(({success, quiz}) => {
      if (success) {
        navigate(`/quizzes/${quizId}`, {
          state: {
            notificationState: {
              title: 'Quiz Published',
              body: `This quiz version will now be assigned to all new assessments.`,
            }
          }
        });
      }
    });
  }

  if (!show || !creator) return null;

  const cantPublish = saving || initQuiz.is_published || formulaHasError || readOnly;
  const canRollback = saving || initQuiz.is_published || readOnly;

  return (
    <>
      <ModalQuizSettings setOpen={(val) => setShowSettings(val)} open={showSettings} quiz={initQuiz} onConfirm={updateQuizSettings} />
      <div className="h-12 bg-blue-50 items-center justify-between space-x-3">
        <div className="h-full flex justify-between px-5 items-center space-x-2">
          <Link to="/quizzes">
            <div className="flex items-center space-x-2">
              <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
              <span>All Quizzes</span>
            </div>
          </Link>
          <span className="font-bold text-blue-700">
            <span>{initQuiz.name}</span>
            <span className="px-1 py-0.5 bg-purple-600 text-purple-100 shadow-sm font-bold text-xs rounded-md ml-2">{initQuiz.type_name}</span>
          </span>
          <div className="space-x-2 flex items-center">
            {!readOnly && <span>{initQuiz.is_published ? 'Published' : 'Not Published'}</span>}
            {readOnly && <span>Read Only</span>}
            <button
              type="button"
              onClick={publish}
              disabled={cantPublish}
              className={`${classNames(cantPublish ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-800')} inline-flex items-center gap-x-1.5 rounded-md py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600`}
            >
              <EyeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Publish
            </button>
            <button
              type="button"
              disabled={canRollback}
              onClick={rollback}
              className={`${classNames(canRollback ? 'bg-gray-500' : 'bg-red-600 hover:bg-indigo-500')} inline-flex items-center gap-x-1.5 rounded-md py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600`}
            >
              <ArrowUturnLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Rollback
            </button>
            { initQuiz.is_for_feedback_only &&
              (
                <Tippy content="Click to copy sharable link">
                  <button
                    disabled={!initQuiz.external_feedback_url}
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(initQuiz.external_feedback_url).then(() => {
                        addNotification({title: 'Copied', body: 'Link copied to clipboard.'})
                      })
                    }}
                    className={`bg-gray-50 border border-gray-400 inline-flex items-center gap-x-1.5 rounded-md p-1 text-sm font-semibold shadow-sm hover:bg-gray-700 text-gray-700 hover:text-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600`}
                  >
                    <LinkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </Tippy>
              )
            }
            <button
              type="button"
              disabled={readOnly}
              onClick={() => setShowSettings(true)}
              className={`bg-gray-50 border border-gray-400 inline-flex items-center gap-x-1.5 rounded-md p-1 text-sm font-semibold shadow-sm hover:bg-gray-700 text-gray-700 hover:text-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600`}
            >
              <CogIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <SurveyCreatorComponent creator={creator} />
    </>
  )
}
