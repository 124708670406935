import React from 'react';
import {MinusIcon} from '@heroicons/react/24/solid';

export function Spinner({textColor='text-white'}) {
  return (
    <span className="animate-spin">
      <MinusIcon className={`h-5 w-5 ${textColor}`} />
    </span>
  )
}
