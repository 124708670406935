import React, {useState} from 'react';
import {fetchApi} from '../utils/fetch';
import {useTimer} from 'use-timer';
import {getSurveyAssessments} from "../fetchers";
import {useSurvey} from "./ContextSurvey";

export const AssessmentsContext = React.createContext({
  assessments: [],
  unpaidAssessments: [],
});

export function useAssessments() {
  return React.useContext(AssessmentsContext);
}

export default function AssessmentsProvider({children, assessments: defaultAssessments}) {
  const {survey} = useSurvey();
  const [assessments, setAssessments] = useState(defaultAssessments);
  const [unpaidAssessments, setUnpaidAssessments] = useState([]);

  function loadAssessments() {
    return getSurveyAssessments(null, survey.id).then((assessments) => {
      setAssessments(assessments);
      setUnpaidAssessments(assessments.filter((a) => !a['paid?']));
    });
  }

  function updateAssessments(updates) {
    return fetchApi(`/api/v1/internal/surveys/${survey.id}`, {method: 'PATCH', body: JSON.stringify({survey: updates})})
      .then(loadAssessments);
  }

  return (
    <AssessmentsContext.Provider value={{assessments, unpaidAssessments, loadAssessments, updateAssessments}}>
      {children}
    </AssessmentsContext.Provider>
  );
}

