export function fetchApi(path, args={}, autoLogout=true) {
  return fetch(path, {...args, headers: getHeaders(args.headers)})
    .then((resp) => {
      if (resp.status === 401 && autoLogout) {
        console.error('User was logged out.', resp.status);
        window.location = '/users/sign_in';
        return;
      }

      return resp.json();
    });
}

export function getHeaders(overwriteHeaders = {}) {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const csrfHeaders = { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json' };
  return {...csrfHeaders, ...overwriteHeaders}
}
