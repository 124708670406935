import React, {Fragment, useEffect, useState} from 'react';
import {
  CalendarDaysIcon,
  CalendarIcon, ExclamationTriangleIcon,
  PlusIcon, UserIcon,
} from "@heroicons/react/24/outline";
import {
  CheckBadgeIcon
} from "@heroicons/react/24/solid";
import {useLoaderData} from "react-router";
import DateFormatter from "./DateFormatter";
import {Link, useNavigate} from "react-router-dom";
import {fetchApi} from "./utils/fetch";
import ModalQuizSettings from "./ModalQuizSettings";
import Tippy from "@tippyjs/react";
import {classNames} from "./utils/classNames";
import {useNotifications} from "./contexts/ContextNotification";
import {NOTIFICATION_TYPES} from "./Notifications";

export default function Quizzes() {
  const navigate = useNavigate();
  const {quizzes, latest_available_version_by_type: latestAvailableVersionByType} = useLoaderData();
  const [showNewQuiz, setShowNewQuiz] = useState(false);
  const [sortedQuizzes, setSortedQuizzes] = useState({});
  const [sortedQuizTypes, setSortedQuizTypes] = useState([]);
  const {addNotification} = useNotifications();

  useEffect(() => {
    if (!quizzes) return;

    const sorted = {};
    quizzes.forEach((q) => {
      const type = q.type_name;
      const version = q.version;

      sorted[type] ||= [];
      sorted[type].push(q);
    });

    setSortedQuizzes(sorted);
    setSortedQuizTypes(Object.keys(sorted));
  }, [quizzes]);

  function createNewQuizType({surveyName, reportTypeId, surveyType, isInternal, isAvailable, isCoachabilityQuiz, isForFeedbackOnly, description, newVersion = false}) {
    return fetchApi(`/api/v1/internal/quizzes`, {
      method: 'POST',
      body: JSON.stringify({
        name: surveyName,
        description,
        survey_type: surveyType,
        new_version: newVersion,
        is_available: isAvailable,
        is_internal: isInternal,
        is_coachability: isCoachabilityQuiz,
        is_for_feedback_only: isForFeedbackOnly,
        report_content_type_id: reportTypeId,
      }),
    }).then((resp) => {
      const {success, quiz, redirect} = resp;

      if (redirect) {
        addNotification({title: 'No need to create', body: 'An version already exists, no need to create a new version', type: NOTIFICATION_TYPES.WARNING});
      }

      if (success) navigate(`/quizzes/${quiz.id}`);

      return resp;
    })
  }

  return (
    <>
      <ModalQuizSettings open={showNewQuiz} onConfirm={createNewQuizType} setOpen={setShowNewQuiz}/>
      <div className="flex items-center justify-between my-5">
        <h2>Quizzes</h2>
        <button
          onClick={() => setShowNewQuiz(true)}
          type="button"
          className="w-full mt-4 sm:mt-0 sm:w-auto flex justify-center items-center space-x-2 rounded-md border border-transparent bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
        >
          <PlusIcon className="h-5 w-5 text-white"/>
          <span>New Quiz Type</span>
        </button>
      </div>
      <div className="">
        {sortedQuizTypes.map((quizType) => {
          return (
            <Fragment key={`quiz_types_${quizType}`}>
              <div className="flex justify-between items-center my-5">
                <p className="font-bold font-mono">{quizType}</p>

                <button
                  type="button"
                  className="flex items-center space-x-2 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  onClick={() => {
                    createNewQuizType({newVersion: true, surveyType: quizType});
                  }}
                >
                  <PlusIcon className="h-5 w-5" />
                  <span>New Version</span>
                </button>
              </div>
              <ul role="list" className="mb-8 space-y-5">
                {
                  sortedQuizzes[quizType].map((quiz, eventIdx) => {
                    delete quiz.quiz
                    const isPrimary = latestAvailableVersionByType[quizType]?.id === quiz.id;
                    return (
                      <li key={quiz.id} className="list-none">
                        <Link to={`/quizzes/${quiz.id}`}>
                          <div className={`relative ${eventIdx !== 0 && '-mt-2'} text-gray-800`} style={{'zIndex:hover': '99999', zIndex: `${sortedQuizzes[quizType].length - eventIdx}`}}>
                            {eventIdx !== sortedQuizzes.length - 1 ? (
                              <span style={{ marginTop: '0' }}  className="absolute left-5 top-0 h-[70%] z-0 -ml-px w-0.5 bg-gray-200" aria-hidden="true"/>
                            ) : null}
                            <div className="relative flex items-start space-x-3">
                              <>
                                <div>
                                  <div className="relative px-1">
                                    { isPrimary && (
                                      <div className="bg-transparent absolute -top-4 left-0 z-40">
                                        <CheckBadgeIcon className="h-5 w-5 text-blue-400" />
                                      </div>
                                    ) }
                                    <div
                                      style={{'--tw-bg-opacity': `${100 - ((eventIdx * 10) ^ 3)}%`}}
                                    >
                                      <span style={{ 'WebkitTextStrokeWidth': '1px', 'WebkitTextStrokeColor': '#111' }} className="font-mono text-2xl italic font-bold block text-white scale-150 pl-1 bg-gray-50 relative z-10">v{quiz.version}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full flex justify-end transition-all hover:z-40 hover:ml-10 transform">
                                  <div style={{ width: `${100 - (eventIdx * 2)}%`}} className={`w-full flex flex-col justify-between space-y-5 rounded-md bg-white shadow p-4`}>
                                    <div className="flex justify-between items-center">
                                      <div className="flex items-center space-x-1">
                                        <span>{quiz.name}</span>
                                        <div className="flex items-center justify-start">
                                          {quiz.is_for_feedback_only && <div className="rounded-full text-xs px-2 scale-75 py-0.5 bg-orange-400 text-white font-bold border-2 border-orange-600">Feedback Only</div>}
                                          <div className={`rounded-full text-xs px-2 scale-75 py-0.5 text-white font-bold border-2 ${classNames(quiz.is_internal ? 'bg-gray-400 border-gray-600' : 'bg-pink-400 border-pink-600')}`}>{quiz.is_internal ? 'Internal' : 'Public'}</div>
                                        </div>
                                      </div>
                                      <div className="flex space-x-2 items-center text-sm">
                                        <UserIcon className="h-5 w-5 flex-shrink-0" />
                                        <div>{quiz.created_by}</div>
                                      </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                      <div className={classNames(isPrimary ? 'opacity-100' : 'opacity-40', 'flex items-center space-x-2')}>
                                        {quiz.is_published && !quiz.is_available && isPrimary && (
                                          <Tippy content="This quiz is published but is not available, users will not be able to select it until it's made available">
                                            <ExclamationTriangleIcon className="text-red-500 h-6 w-6" />
                                          </Tippy>
                                        )}
                                        {quiz.is_available && <div className="flex items-center rounded-full text-xs px-2 py-0.5 space-x-2 bg-green-400 text-white font-bold border-2 border-green-600">Available</div>}
                                        {!quiz.is_available && <div className="flex items-center rounded-full text-xs px-2 py-0.5 space-x-2 bg-gray-400 text-white font-bold border-2 border-gray-600">Not Available</div>}
                                        {quiz.is_published && <div className="flex items-center rounded-full text-xs px-2 py-0.5 space-x-2 bg-blue-400 text-white font-bold border-2 border-blue-600">Published</div>}
                                        {!quiz.is_published && <div className="flex items-center rounded-full text-xs px-2 py-0.5 space-x-2 bg-orange-400 text-white font-bold border-2 border-orange-600">Draft</div>}
                                        {quiz.read_only && <div className="flex items-center rounded-full text-xs px-2 py-0.5 space-x-2 bg-gray-400 text-white font-bold border-2 border-gray-600">Read Only</div>}
                                        {!quiz.read_only && <div className="flex items-center rounded-full text-xs px-2 py-0.5 space-x-2 bg-purple-400 text-white font-bold border-2 border-purple-600">Editable</div>}
                                      </div>
                                      <Tippy content="Date and time last published">
                                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 text-gray-800">
                                          <CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                          {quiz.published_at ? <DateFormatter dateString={quiz.published_at}/> : 'Never published' }
                                        </div>
                                      </Tippy>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </div>
                            {/*<pre>{JSON.stringify(quiz, null, 2)}</pre>*/}
                          </div>
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </Fragment>
          )
        })}
      </div>
    </>
  )
}
