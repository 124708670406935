import React from 'react';
import {RadioGroup} from '@headlessui/react';
import {classNames} from './utils/classNames';
import {useSurvey} from './contexts/ContextSurvey';

export default function SurveyEndsOnRange(props) {
  const {onChange} = props;
  const {survey} = useSurvey();

  return (
    <RadioGroup onChange={(value) => onChange(value)} className="mt-2" defaultValue={survey.ends_on_range}>
      <div className="sm:flex justify-between items-center sm:space-x-2 space-y-2 sm:space-y-0">
        {[30, 60, 90].map((option) => (
          <RadioGroup.Option
            key={option}
            value={option}
            className={({ active, checked }) =>
              classNames(
                active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                checked
                  ? 'bg-blue-600 border-transparent text-white hover:bg-blue-700'
                  : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                'border rounded-md py-3 px-3 space-x-2 cursor-pointer flex items-center justify-center text-sm font-medium sm:flex-1'
              )
            }
          >
            <RadioGroup.Label as="span">{option} days</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
