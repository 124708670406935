import React, {Fragment, useMemo, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {CurrencyDollarIcon, MinusIcon, PlusIcon} from '@heroicons/react/24/outline'
import {Button} from './Button';
import {fetchApi} from './utils/fetch';
import CoinsIcon from "./icons/CoinsIcon";

export default function ModalSurveyCheckout({onCheckoutComplete = () => {}, survey, availableTokens = 0, purchaseQuantity, open, setOpen, url, checkoutRequestBody = {}}) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tokensToUse, setTokensToUse] = useState(0);
  const maxAvailableTokens = useMemo(() => (
    Math.min(...[availableTokens, purchaseQuantity].map((i) => parseInt(i)))
  ), [availableTokens, purchaseQuantity]);

  function sendToCheckout() {
    setLoading(true);
    fetchApi(url || `/api/v1/internal/surveys/${survey.id}/checkout`, {
      method: 'POST',
      body: JSON.stringify({
        tokens_to_use: tokensToUse,
        ...checkoutRequestBody,
      }),
    })
    .then(({success, msg, checkout_url}) => {
      if (checkout_url) {
        window.open(checkout_url, '_self');
      } else if (!success) {
        setErrorMessage(msg);
      } else if (success && !checkout_url) {
        onCheckoutComplete(success);
        setOpen(false);
      }
    }).finally(() => setLoading(false));
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {
        setErrorMessage(null);
        setTokensToUse(0);
        setOpen(false);
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CurrencyDollarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Just one more thing
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Would you like to be sent to our payment website?
                      </p>
                      { errorMessage && (
                        <p className="text-sm mt-2 text-red-500">
                          {errorMessage}
                        </p>
                      )}
                    </div>
                    {
                      !!maxAvailableTokens && (
                        <>
                          <div className="mt-5 inline-flex w-auto items-center space-x-2">
                            <CoinsIcon color='black' className="h-5 w-5 inline-block" />
                            <div className="bg-gray-200 rounded-md flex items-center">
                              <span role="button" onClick={() => tokensToUse > 0 && setTokensToUse(tokensToUse - 1)} className="active:ring-2 ring-gray-200 bg-gray-400 text-white font-bold rounded-l-md p-1 inline-flex items-center">
                                <MinusIcon className="inline-block h-5 w-5" />
                              </span>
                              <span className="px-2">
                                {tokensToUse} / {maxAvailableTokens}
                              </span>
                              <span role="button" onClick={() => tokensToUse < maxAvailableTokens && setTokensToUse(tokensToUse + 1)} className="active:ring-2 ring-gray-200 bg-gray-400 text-white font-bold rounded-r-md p-1 inline-flex items-center">
                                <PlusIcon className="inline-block h-5 w-5" />
                              </span>
                            </div>
                          </div>
                          <div className="text-gray-400 text-xs">You have tokens you can use.</div>
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  {
                    tokensToUse === purchaseQuantity ? (
                      <Button color={tokensToUse ? 'yellow' : 'blue'} onClick={() => sendToCheckout()} loading={loading}>Pay with tokens</Button>
                    ) : (
                      <Button color={tokensToUse ? 'yellow' : 'blue'} onClick={() => sendToCheckout()} loading={loading}>Pay with cash {!!tokensToUse && ('and tokens')}</Button>
                    )
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
