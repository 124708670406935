import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";

export default function Pages({maxPage, queryKey, additionalSearchParams}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(searchParams[queryKey] || 1);

  useEffect(() => {
    if (currentPage > maxPage) return setCurrentPage(maxPage);
    if (currentPage < 1) return setCurrentPage(1);

    setSearchParams({...Object.fromEntries(searchParams), ...additionalSearchParams, [queryKey]: currentPage});
  }, [currentPage, additionalSearchParams]);

  return (
    <div className="flex justify-end space-x-4 items-center">
      <button
        onClick={() => setCurrentPage(currentPage - 1)}
        type="button"
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <ArrowLeftIcon className="h-5 w-5" />
      </button>
      <button
        onClick={() => setCurrentPage(currentPage + 1)}
        type="button"
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <ArrowLeftIcon className="h-5 w-5 rotate-180" />
      </button>
    </div>
  )
}