import React, {useEffect, useState} from 'react';
import {Bars4Icon, ExclamationTriangleIcon, Squares2X2Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {classNames} from "./utils/classNames";
import {useLoaderData} from "react-router";
import {useUser} from "./contexts/ContextUser";
import DebugPrinter from "./DebugPrinter";
import Pages from "./Pages";
import {HeartIcon} from "@heroicons/react/24/solid";
import {Button} from "./Button";

const tabs = [{display: 'Gold', name: 'gold', color: 'border-yellow-400 text-yellow-400'}, {display: 'Silver', name: 'silver', color: 'text-gray-400 border-gray-400'}, {display: 'Bronze', name: 'bronze', color: 'text-orange-700 border-orange-700'}];

export default function ResourcesDrawer() {
  const {documents: activeDocuments, pages: activePages, restricted} = useLoaderData();
  const {user} = useUser();
  const [activeTab, setActiveTab] = useState(findInitActiveTab() || tabs[0]);
  const [currentFile, setCurrentFile] = useState(null);
  const [categorized, setCategorized] = useState({});
  const [pagesSearchParams, setPagesSearchParams] = useState({});

  useEffect(() => findInitActiveTab() && setActiveTab(findInitActiveTab()), [user]);

  useEffect(() => {
    const categorized = {};

    activeDocuments.forEach((doc) => {
      const category = doc.category || 'uncategorized';
      categorized[category] ||= []
      categorized[category].push(doc);
    });

    setCategorized(categorized);
  }, [activeDocuments]);

  useEffect(() => {
    setPagesSearchParams({ctl: activeTab?.name});
  }, [activeTab?.name]);

  function findInitActiveTab() {
    return tabs.find((t) => t.name === user.certification_level);
  }

  function getFileType(filename) {
    switch (filename.split('.').pop()) {
      case 'pdf':
        return 'PDF';
      case 'pptx':
        return 'PowerPoint';
      case 'doc':
      case 'docx':
        return 'Word';
      default:
        return null;
    }
  }

  function getSizeInMb(size) {
    return Math.ceil(size / 1024 / 1024 * 100) / 100;
  }

  function renderCurrentDocumentSlideout(currentFile) {
    if (!currentFile) return;

    return (
      <aside className="h-full fixed right-0 sm:z-0 z-10 top-0 sm:pt-20 pt-8 px-8 w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
        <div className="space-y-6 pb-16">
          <div>
            <div className='flex justify-end mb-5'>
              <XMarkIcon className="h-7 w-7" onClick={() => setCurrentFile(null)} />
            </div>
            <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
              <img src={currentFile.doc.preview} alt="" className="object-cover" />
            </div>
            <div className="mt-4 flex items-start justify-between">
              <div>
                <h2 className="text-lg font-medium text-gray-900">
                  <span className="sr-only">Details for </span>
                  {currentFile.name}
                </h2>
                <p className="text-sm font-medium text-gray-500">{getSizeInMb(currentFile.doc.metadata.size)} MB</p>
              </div>
              <button
                type="button"
                className="hidden ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <HeartIcon className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">Favorite</span>
              </button>
            </div>
          </div>
          <div>
            <h3 className="font-medium text-gray-900">Information</h3>
            <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Created</dt>
                <dd className="w-2/3 text-right whitespace-nowrap text-gray-900 truncate" dangerouslySetInnerHTML={{__html: currentFile.created_at}}></dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Type</dt>
                <dd className="whitespace-nowrap text-gray-900 truncate">{getFileType(currentFile.doc.metadata.filename)}</dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Size</dt>
                <dd className="whitespace-nowrap text-gray-900">{getSizeInMb(currentFile.doc.metadata.size)} MB</dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium" title={currentFile.doc.metadata.filename}>
                <dt className="text-gray-500">Name</dt>
                <dd className="w-2/3 text-right whitespace-nowrap text-gray-900 truncate">{currentFile.doc.metadata.filename}</dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Updated</dt>
                <dd className="w-2/3 text-right whitespace-nowrap text-gray-900 truncate" dangerouslySetInnerHTML={{__html: currentFile.updated_at}}></dd>
              </div>
            </dl>
          </div>
          <div>
            <h3 className="font-medium text-gray-900">Description</h3>
            <div className="mt-2 flex items-center justify-between">
              { !currentFile.description && <p className="text-sm italic text-gray-500">There is no description for this file.</p> }
              { currentFile.description && <p className="text-sm text-gray-800" dangerouslySetInnerHTML={{__html: currentFile.description}}></p> }
            </div>
          </div>
          <Button
            disabled={currentFile.restricted}
            color="orange"
            onClick={() => window.open(currentFile.download, '_blank')}
          >
            Download
          </Button>
        </div>
      </aside>
    );
  }

  return (
    <>
      <DebugPrinter json={categorized} />
      <div className="flex flex-1 items-stretch overflow-hidden">
        <main className="flex-1 overflow-y-auto">
          <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
            <div className="flex">
              <h1 className="flex-1 text-2xl font-bold text-gray-900">Resources</h1>
              <div className="ml-6 flex items-center rounded-lg bg-gray-100 p-0.5 hidden">
                <button
                  type="button"
                  className="rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
                >
                  <Bars4Icon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Use list view</span>
                </button>
                <button
                  type="button"
                  className="ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
                >
                  <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Use grid view</span>
                </button>
              </div>
            </div>
            <div className="mt-3 sm:mt-2">
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  value={activeTab.name}
                  onChange={({target: {value}}) => setActiveTab(tabs.find((t) => t.name === value.toLowerCase()))}
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                >
                  {
                    tabs.map((tab) => {
                      return (<option key={`mini_tab_${tab.name}`} value={tab.name}>{tab.display}</option>)
                    })
                  }
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="flex items-center border-b border-gray-200">
                  <nav className="-mb-px flex flex-1 space-x-6 xl:space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <div
                        key={`tab_${tab.name}`}
                        onClick={() => {
                          setActiveTab(tab);
                          setCurrentFile(null);
                        }}
                        className={classNames(
                          activeTab.name === tab.name
                            ? tab.color
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap py-4 px-1 cursor-pointer border-b-2 font-medium text-sm capitalize'
                        )}
                      >
                        {tab.name}
                      </div>
                    ))}
                  </nav>
                  <div className="ml-6 hidden items-center rounded-lg bg-gray-100 p-0.5">
                    <button
                      type="button"
                      className="rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
                    >
                      <Bars4Icon className="h-5 w-5" aria-hidden="true" />
                      <span className="sr-only">Use list view</span>
                    </button>
                    <button
                      type="button"
                      className="ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
                    >
                      <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                      <span className="sr-only">Use grid view</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
              {
                restricted && (
                  <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-8">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          {
                            user.certification_level ?
                            (
                              <span>Your certification level is <span className="capitalize font-bold">{user.certification_level}</span> so you cannot use any of the documents shown below.</span>
                            ) : (
                              <span>You currently aren't certified.</span>
                            )
                          }
                          {' '}
                          <a href="/book_time" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                            Please contact us about upgrading your certification!
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                !activeDocuments.length && (
                  <div className="w-full h-96 border-2 border-dashed border-gray-600 flex flex-col items-center justify-center rounded-lg my-10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                    </svg>
                    <p className="text-center">There are no files with this certification level yet!</p>
                  </div>
                )
              }
                {Object.keys(categorized).map((cat) => {
                  const documents = categorized[cat];

                  return (
                    <div key={`category_${cat}`}>
                      <div className="text-xl capitalize font-bold border-b-2 border-gray-200 my-4 pb-1">{cat}</div>
                      <ul
                        role="list"
                        className="list-none grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                      >
                        {
                          documents.map((file) => {
                            return (
                              <li key={file.name} className="relative">
                                <div
                                  onClick={() => {
                                    setCurrentFile(file);
                                  }}
                                  className={classNames(
                                    file.id === currentFile?.id
                                      ? 'ring-2 ring-offset-2 ring-orange-500'
                                      : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-orange-500',
                                    'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
                                  )}
                                >
                                  <img
                                    src={file.doc.preview}
                                    alt={file.name}
                                    className={classNames(
                                      currentFile?.id === file.id ? '' : 'group-hover:opacity-75',
                                      'object-cover pointer-events-none'
                                    )}
                                  />
                                  <button type="button" className="absolute inset-0 focus:outline-none">
                                    <span className="sr-only">View details for {file.name}</span>
                                  </button>
                                </div>
                                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                                  {file.name}
                                </p>
                                <div className="flex justify-between items-center">
                                  <p
                                    className="pointer-events-none block text-sm font-medium text-gray-500">{getSizeInMb(file.doc.metadata.size)} MB</p>
                                  <p
                                    className="pointer-events-none block text-sm font-medium text-gray-500">{getFileType(file.doc.metadata.filename)}</p>
                                </div>
                              </li>
                            );
                          })
                        }
                      </ul>
                    </div>
                  )})
                }
              <Pages queryKey='drp' additionalSearchParams={pagesSearchParams} maxPage={activePages?.pages} />
            </section>
          </div>
        </main>
        {renderCurrentDocumentSlideout(currentFile)}
      </div>
    </>
  )
}