import React, {useCallback, useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';

export const NotificationContext = React.createContext({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
});

export default function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [removes, setRemoves] = useState({});

  useEffect(() => {
    const notificationsToSet = notifications.map((note) => {
      if (!note.show) return false;
      if (removes[note.id]) note.show = false;
      return note;
    }).filter((n) => !!n);

    setNotifications(notificationsToSet);
  }, [removes]);

  const addNotification = useCallback((notification) => {
    const uuid = uuidv4();
    const notes = notifications.filter((n) => n.show);

    setNotifications([...notes, {
      ...notification,
      createdAt: new Date(),
      show: true,
      id: uuid,
    }]);

    setTimeout(() => {
      setRemoves({...removes, [uuid]: true});
    }, 5000);
  }, [notifications, setNotifications]);

  const removeNotification = useCallback((notification) => {
    const idxToRemove = notifications.findIndex(({id}) => notification.id === id);
    const newNotifications = [...notifications];
    newNotifications[idxToRemove].show = false
    setNotifications(newNotifications);
  });

  return (
    <NotificationContext.Provider value={{notifications, addNotification, removeNotification}}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotifications() {
  return React.useContext(NotificationContext);
}
