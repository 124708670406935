import React, {Fragment, useEffect, useState} from 'react';
import {
  CalendarIcon,
  UserIcon,
  DocumentDuplicateIcon,
  UsersIcon, ClipboardDocumentCheckIcon, PencilIcon, CheckIcon, DocumentIcon
} from '@heroicons/react/24/outline'
import {Button} from './Button';
import {fetchApi} from './utils/fetch';
import {classNames} from "./utils/classNames";
import ModalSurveyCheckout from "./ModalSurveyCheckout";
import {getTeamReportSurveySearch} from "./fetchers";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import {useNavigate} from "react-router";
import * as PropTypes from "prop-types";

let controller = null;
let signal = null;

function SurveySearchBar({onSearch}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('survey');
  useEffect(() => {
    onSearch(searchTerm, searchType);
  }, [searchTerm, searchType]);

  function toggleSearch() {
    setSearchType(searchType === 'survey' ? 'participant' : 'survey');
    setSearchTerm('');
  }

  return (
    <div>
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            placeholder={searchType === 'survey' ? 'Survey name' : 'Participant name'}
            onChange={({target: {value}}) => setSearchTerm(value)}
          />
        </div>
        <button
          type="button"
          onClick={toggleSearch}
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <span className="-ml-0.5 text-gray-400">{searchType === 'survey' ? (<DocumentIcon className="h-5 w-5" />) : <UsersIcon className="h-5 w-5" aria-hidden="true" />}</span>
          <span className="capitalize">{searchType}</span>
        </button>
      </div>
    </div>
  );
}

SurveySearchBar.propTypes = {onSearch: PropTypes.func};

export default function TeamReportNew() {
  const [currentPage, setCurrentPage] = useState(1);
  const [surveys, setSurveys] = useState([]);
  const [allLoaded, setAllLoaded] = useState(false);
  const [surveyAssessments, setSurveyAssessments] = useState({});
  const [hasNamedReport, setHasNamedReport] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [selectedAssessments, setSelectedAssessments] = useState({});
  const [loadingAssessments, setLoadingAssessments] = useState(null);
  const [searchTerms, setSearchTerms] = useState({term: undefined, type: undefined});
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState([]);
  const [teamReportTitle, setTeamReportTitle] = useState('');
  const [selectedQuizType, setSelectedQuizType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const surveyIds = Object.keys(selectedAssessments);

    if (!surveyIds.length) {
      setSelectedQuizType(null);
    } else {
      const assessmentIds = Object.keys(selectedAssessments[surveyIds[0]].assessments);
      setSelectedQuizType(selectedAssessments[surveyIds[0]].assessments[assessmentIds[0]].quiz);
    }
  }, [selectedAssessments]);

  useEffect( () => {
    loadSurveys(true);
  }, [currentPage]);

  useEffect( () => {
    loadSurveys(false);
  }, [searchTerms]);

  function loadSurveys(append=false) {
    if (signal) controller.abort();
    controller = new AbortController();
    signal = controller.signal;

    return getTeamReportSurveySearch(signal, currentPage, searchTerms.term, searchTerms.type).then(({surveys: newSurveys, pages: {page, pages}}) => {
      // append results
      if (append) {
        setSurveys([...surveys, ...newSurveys]);
      } else {
        setSurveys(newSurveys);
      }

      setAllLoaded(pages === page);
    });
  }

  function loadSurveyAssessments(survey) {
    setLoadingAssessments(survey.id);
    fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments`, {method: 'GET'})
    .then((assessments) => {
      setSurveyAssessments({...surveyAssessments, [survey.id]: assessments});
    }).finally(() => setLoadingAssessments(null));
  }

  function generateSelectedAssessments(survey, assessments) {
    const newSelectedAssessments = {
      ...selectedAssessments,
      [survey.id]: {
        id: survey.id,
        name: survey.name,
        assessments: {
          ...(selectedAssessments[survey.id]?.assessments || {}),
        },
      }
    };

    assessments.filter((a) => a.completed).forEach((assessment) => {
      newSelectedAssessments[survey.id].assessments[assessment.id] = {
        id: assessment.id,
        completed: assessment.completed,
        type: assessment.assessment_type,
        group: assessment.group_number,
        participant: assessment.participant,
        quiz: assessment.quiz,
      };
    });

    return newSelectedAssessments;
  }

  function removeAssessment(surveyId, assessment) {
    const newSelectedAssessments = {...selectedAssessments};

    delete newSelectedAssessments[surveyId].assessments[assessment.id]

    if (!Object.keys(newSelectedAssessments[surveyId].assessments).length) {
      delete newSelectedAssessments[surveyId];
    }

    setSelectedAssessments(newSelectedAssessments);
  }

  function addAssessment(survey, assessment) {
    if (!Object.keys(selectedAssessments).length) {
      setSelectedQuizType(assessment.quiz);
    }

    if (assessment.completed) {
      setSelectedAssessments(generateSelectedAssessments(survey, [assessment]));
    }
  }

  function isDifferentQuizType(assessment) {
    return !!selectedQuizType && (selectedQuizType?.type_name !== assessment.quiz.type_name || selectedQuizType?.version !== assessment.quiz.version);
  }

  function canAddAssessment(assessment) {
    if (isDifferentQuizType(assessment)) return false;

    return assessment.completed;
  }

  function getAssessmentQuizTypeName(assessment) {
    return `${assessment.quiz.type_name}${assessment.quiz.version}`;
  }

  return (
    <div>
      {
        hasNamedReport ? (
          <div className="pb-20">
            <div className="flex justify-between items-center mt-3">
              <div>
                { editTitle ? (
                  <div className="flex space-x-2 items-center">
                    <input value={teamReportTitle} onChange={({target}) => setTeamReportTitle(target.value)} type="text" name="team_report_name" id="survey_name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm" placeholder="Title of your Team Report..." />
                    <CheckIcon className='w-6 h-6 cursor-pointer' onClick={() => setEditTitle(false)} />
                  </div>
                ) : (
                  <h2 className="flex items-center space-x-2">
                    {teamReportTitle}
                    <PencilIcon className="w-6 h-6" onClick={() => setEditTitle(true)} />
                  </h2>
                )}
              </div>
              <div>
                <Button disabled={!Object.keys(selectedAssessments).length} onClick={() => {
                  setSelectedAssessmentIds(Object.values(selectedAssessments).map(({assessments}) => assessments).map(Object.keys).flat());
                  setShowCheckoutModal(true);
                }}>Buy Team Report</Button>
              </div>
            </div>
            <p className="text-lg text-gray-800 mt-5 w-full sm:w-3/4">Welcome to Team Reports Builder. Here you can compile a team report consisting of any completed assessment from any survey.</p>

            <div className="flex sm:h-screen mt-5">
              <ModalSurveyCheckout
                onCheckoutComplete={() => {
                  setShowCheckoutModal(false);
                  navigate('/');
                }}
                purchaseQuantity={1}
                availableTokens={1}
                setOpen={(open) => setShowCheckoutModal(open)}
                open={showCheckoutModal}
                url='/api/v1/internal/team_reports'
                checkoutRequestBody={{
                  title: teamReportTitle,
                  assessment_ids: selectedAssessmentIds,
                  quiz_type_name: selectedQuizType?.type_name,
                  quiz_version: selectedQuizType?.version,
                }}
              />
              {/*<p className="text-gray-400 text-xs">Note: This title will be shown in the downloaded Team Report</p>*/}
              <div className="flex min-w-0 flex-1 flex-col overflow-hidden h-full">
                <div className="relative z-0 sm:flex sm:flex-1 sm:overflow-hidden sm:space-x-5 sm:divide-x sm:divide-gray-300">
                  <aside className="sm:relative sm:w-[40%] sm:flex-shrink-0 sm:overflow-y-auto sm:flex sm:flex-col">
                    <div className="sm:absolute inset-0 space-y-3">
                      <SurveySearchBar onSearch={(term, type) => {
                        setSearchTerms({term, type});
                        setCurrentPage(1);
                      }} />
                      <ul role="list" className="overflow-scroll sm:overflow-hidden h-96 sm:h-auto divide-y divide-gray-300 list-none bg-white rounded-md border border-gray-300">
                        {surveys.map((survey, idx) => {
                          let moreThanOneType = false
                          const eligibleAssessments = surveyAssessments[survey.id]?.filter(canAddAssessment) || [];
                          if (surveyAssessments[survey.id]) {
                            moreThanOneType = Object.keys(surveyAssessments[survey.id].reduce((acc, assessment) => {
                              if (!assessment.completed) return acc;
                              const type = getAssessmentQuizTypeName(assessment);
                              acc[type] ||= 0;
                              acc[type] += 1;
                              return acc;
                            }, {})).length > 1;
                          }

                          const canAddAll = eligibleAssessments.length && !moreThanOneType;

                          return (
                            <li key={`pick_list_${survey.id}_${idx}`}>
                              <div className="block hover:bg-gray-50 cursor-pointer" onClick={() => !surveyAssessments[survey.id] && loadSurveyAssessments(survey)}>
                                <div className="px-4 py-4 sm:px-6">
                                  <div className="flex items-center justify-between">
                                    <p className="truncate text-sm font-medium text-orange-600">{survey.name}</p>
                                    <div className="ml-2 flex flex-shrink-0">
                                      <p className="inline-flex items-center rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                                        <DocumentDuplicateIcon className="mr-1.5 h-3 w-3 flex-shrink-0 text-blue-500" aria-hidden="true" />
                                        {survey.assess_count}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="mt-2 space-y-2">
                                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                      <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                      {survey.created_ago} ago
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                      <UserIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                      {survey.created_by}
                                    </div>
                                  </div>
                                  <div className="transition-all">
                                    {
                                      surveyAssessments[survey.id] && (
                                        <>
                                          <div className="divide-y divide-gray-100 border border-gray-200 rounded-md px-4 py-1 mt-5 bg-white">
                                            {
                                              surveyAssessments[survey.id].map((assessment) => {
                                                let canRemove = false;
                                                let selectedSurvey = selectedAssessments[survey.id];
                                                if (selectedSurvey && selectedSurvey.assessments[assessment.id]) canRemove = true;

                                                let tippyAddContent = undefined;
                                                tippyAddContent = !assessment.completed && 'This assessment has not been completed yet.';
                                                if (!tippyAddContent && isDifferentQuizType(assessment)) tippyAddContent = 'This assessment is complete but is a different quiz version than the selected quizzes';

                                                return (
                                                  <div
                                                    key={`survey_${survey.id}_assessment_${assessment.id}`}
                                                    className={classNames(assessment.completed ? 'text-gray-800' : 'text-gray-400', 'cursor-default flex justify-between py-2')}
                                                  >
                                                    <div className="space-x-2 flex items-center">
                                                      <Tippy content={`Group ${assessment.group_number}`}>
                                                        <span className="flex flex-grow-1 items-center space-x-1 text-xs text-gray-800">
                                                          <UsersIcon className="w-3 h-3" />
                                                          {assessment.group_number}
                                                        </span>
                                                      </Tippy>
                                                      <Tippy content={`Group ${assessment.group_number}`}>
                                                        <span className="flex flex-grow-1 items-center space-x-1 text-xs text-gray-800 capitalize">
                                                          <DocumentDuplicateIcon className="w-3 h-3" />
                                                          {assessment.quiz.type_name}{assessment.quiz.version && ` v${assessment.quiz.version}`}
                                                        </span>
                                                      </Tippy>
                                                      <span>{assessment.participant.first_name} {assessment.participant.last_name}</span>
                                                    </div>
                                                    {
                                                      canRemove ? (
                                                        <div
                                                          onClick={() => removeAssessment(survey.id, assessment)}
                                                          className="inline-flex items-center rounded-full space-x-2 border border-red-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 shadow-sm hover:bg-gray-50"
                                                        >
                                                          <MinusIcon className="h-4 w-4" />
                                                          Remove
                                                        </div>
                                                      ) : (
                                                        <Tippy disabled={!tippyAddContent} content={tippyAddContent}>
                                                          <div
                                                            onClick={() => canAddAssessment(assessment) && addAssessment(survey, assessment)}
                                                            className={classNames(canAddAssessment(assessment) ? 'cursor-pointer border-green-300' : 'cursor-not-allowed border-gray-300', "inline-flex items-center rounded-full space-x-2 border bg-white px-2.5 py-0.5 text-sm font-medium leading-5 shadow-sm hover:bg-gray-50")}
                                                          >
                                                            <PlusIcon className="h-4 w-4" />
                                                            Add
                                                          </div>
                                                        </Tippy>
                                                      )
                                                    }
                                                  </div>
                                                )
                                              })
                                            }
                                          </div>
                                          <div
                                            className={classNames(canAddAll ? 'border-green-300 hover:bg-gray-50': 'border-gray-300 bg-opacity-75', `w-full mt-3 text-center rounded-full space-x-2 border bg-white px-2.5 py-0.5 text-sm font-medium leading-5 shadow-sm`)}
                                            onClick={() => {
                                              if (canAddAll) setSelectedAssessments(generateSelectedAssessments(survey, surveyAssessments[survey.id]));
                                            }}
                                          >
                                            Add All
                                          </div>
                                        </>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                        <li className='px-6 py-4 text-center' onClick={() => !allLoaded && setCurrentPage(currentPage + 1)}>
                          { allLoaded ? 'All surveys loaded' : 'Load More...' }
                        </li>
                      </ul>
                    </div>
                  </aside>
                  {
                    !Object.keys(selectedAssessments).length ? (
                      <div className="sm:h-screen flex flex-col justify-center items-center w-full px-4 mx-auto">
                        <div className="h-96 w-full border-2 border-dashed rounded-lg border-gray-400 flex flex-col items-center justify-center">
                          <div className="flex items-center space-x-2">
                            <ClipboardDocumentCheckIcon className="mx-auto w-12 h-12 text-gray-700" />
                            <p className="font-bold text-sm text-gray-600 w-56">Add survey assessments from the list on the left to create a new Team Report</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <main className="sm:relative sm:z-0 sm:flex-1 sm:overflow-y-auto sm:focus:outline-none">
                        <div className="sm:absolute inset-0 ml-5">
                          <div className="flow-root">
                            { !!selectedQuizType && <div className="mb-2">You have selected an assessment with the quiz type of <b>{selectedQuizType.type_name}{selectedQuizType.version ? ` v${selectedQuizType}` : ''}</b>. You may only select assessments of this type.</div> }
                            <div className="overflow-x-auto">
                              <div className="inline-block min-w-full align-middle border border-gray-200 rounded-lg">
                                <table className="min-w-full shadow-sm rounded-lg overflow-hidden">
                                  <thead className="bg-white">
                                  <tr>
                                    <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                      Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Type
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Email
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Group
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-6 sm:pr-3">
                                    </th>
                                  </tr>
                                  </thead>
                                  <tbody className="bg-white">
                                  {
                                    Object.values(selectedAssessments).map((survey) => {
                                      return (
                                        <Fragment key={survey.id}>
                                          <tr className="border-t border-gray-200">
                                            <th
                                              colSpan={5}
                                              scope="colgroup"
                                              className="bg-gray-50 py-2 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                            >
                                              <div className="flex justify-between w-full">
                                                <span>{survey.name}</span>
                                                <span onClick={() => {
                                                  const newSelectedAssessments = {...selectedAssessments};
                                                  delete newSelectedAssessments[survey.id];
                                                  setSelectedAssessments(newSelectedAssessments);
                                                }} className="text-red-500">Remove All</span>
                                              </div>
                                            </th>
                                          </tr>
                                          {
                                            Object.values(survey.assessments).map((assessment) => {
                                              const participant = assessment.participant;

                                              return (
                                                <tr
                                                  key={`${participant.email}_${assessment.id}`}
                                                  className={classNames('border-gray-200', 'border-t')}
                                                >
                                                  <td
                                                    className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                    {participant.first_name}
                                                  </td>
                                                  <td
                                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{assessment.type}</td>
                                                  <td
                                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{participant.email}</td>
                                                  <td
                                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assessment.group}</td>
                                                  <td
                                                    className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium sm:pr-3">
                                                    <button onClick={() => removeAssessment(survey.id, assessment)} className="text-red-500">
                                                      Remove
                                                    </button>
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          }
                                        </Fragment>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </main>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        ) : (
        <div className="h-screen w-full sm:w-3/4 flex flex-col items-center justify-center mx-auto -mt-32">
          <div className="w-2/3 space-y-4">
            <h3>Title your team report...</h3>
            <div className="sm:flex items-center sm:space-x-2">
              <input onChange={({target}) => setTeamReportTitle(target.value)} type="text" name="team_report_name" id="survey_name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm" autoFocus placeholder="Title of your Team Report..." />
              <button onClick={() => !!teamReportTitle && setHasNamedReport(true)} type="submit" className="w-full mt-3 sm:mt-0 sm:w-auto inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">Create</button>
            </div>
          </div>
        </div>
        )
      }
    </div>
  )
}
