import React, {Fragment, useEffect, useState} from 'react';
import { Menu, Transition } from '@headlessui/react';
import {getSurveyAssessments} from './fetchers';
import {fetchApi, getHeaders} from './utils/fetch';
import {classNames} from './utils/classNames';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon, ArrowPathIcon, HandRaisedIcon, LinkIcon,
  NoSymbolIcon,
  PaperAirplaneIcon, PencilSquareIcon,
  UserPlusIcon
} from '@heroicons/react/24/outline';
import {parseSurveyState} from './utils/state';
import {useNotifications} from './contexts/ContextNotification';
import {Spinner} from './Spinner';
import {Button} from './Button';
import SwitchToggle from './SwitchToggle';
import {useSurvey} from './contexts/ContextSurvey';
import ModalAddParticipant from './ModalAddParticipant';
import ModalSurveyCheckout from './ModalSurveyCheckout';
import ModalAlert from './ModalAlert';
import CoinsIcon from "./icons/CoinsIcon";
import {BanknotesIcon} from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import {useUser} from "./contexts/ContextUser";
import {AdminRequired} from "./App";

let liveReloadAssessmentsInt;

export default function SurveyAssessments({assessments, liveReload, minimizedView = false}) {
  const {user: {admin: isUserAdmin, account: {tokens_available: tokens}}} = useUser();
  const [editAssessment, setEditAssessment] = useState({});
  const [updatedAssessments, setUpdatedAssessments] = useState(null);
  const [showParticipant, setShowParticipant] = useState(false);
  const [modalAlert, setModalAlert] = useState({title: 'Your Report isn\'t ready yet.', body: 'Please try again in a few seconds!', confirmButtonText: 'Close', open: false});
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [downloadingTeamReport] = useState(false);
  const {addNotification} = useNotifications();
  const {survey, updateSurvey, loadSurvey} = useSurvey();

  assessments = updatedAssessments || assessments;

  useEffect(() => {
    if (liveReload) {
      liveReloadAssessmentsInt = setInterval(loadAssessments, 5000);
    } else if (!liveReload) {
      clearInterval(liveReloadAssessmentsInt);
    }
  }, [liveReload]);

  function loadAssessments() {
    return getSurveyAssessments(null, survey.id)
      .then((response) => {
        setUpdatedAssessments(response);
      });
  }

  async function updateParticipant(id, event) {
    await fetchApi(`/api/v1/internal/participants/${id}`, { method: 'PATCH', body: JSON.stringify({[event.target.name]: event.target.value})})
    loadAssessments();
  }

  async function updateAssessment(id, key, {target}) {
    await fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        [key]: target.value,
      }),
    });

    loadAssessments();
  }

  function disableAssessment(assessment) {
    const {id, disabled, participant} = assessment;
    fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments/${id}/disable`, {method: 'PATCH', body: JSON.stringify({
      disable: !disabled,
    })})
    .then(() => addNotification({title: 'Assessment updated!', body: `Assessment for ${participant.first_name} ${participant.last_name} has been ${!disabled ? 'disabled' : 'enabled'}.`}))
    .finally(loadAssessments);
  }

  function syncAssessment(assessment) {
    const {id} = assessment;

    fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments/${id}/sync`, {method: 'POST'})
      .then(({success}) => {
        if (success) {
          addNotification({
            title: 'Assessment has been synced',
            body: 'Please wait a few seconds for the assessment status to be reflected below.'
          });
        } else {
          addNotification({
            title: 'Assessment did NOT sync',
            type: 'failure',
            body: 'This is likely because the assessment was not in the correct state of "completion_pending" so we did not proceed with the sync.'
          });
        }
      })
      .finally(loadAssessments);
  }

  function removeAssessment(assessment) {
    const {id, participant} = assessment;
    fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments/${id}`, {method: 'DELETE'})
      .then(({success, msg}) => {
        if (success) {
          addNotification({
            title: 'Assessment removed.',
            body: `Assessment for ${participant.email} has been removed from this survey.`
          });
        } else {
          addNotification({
            title: 'Assessment was not removed',
            type: 'failure',
            body: msg,
          })
        }
      })
      .finally(loadAssessments);
  }

  function createNewParticipant() {
    loadAssessments();
    setShowParticipant(false);
  }

  function resentInvitationEmail(assessmentId) {
    fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments/${assessmentId}/resend_invite`, {
      method: 'POST',
    }).then(loadAssessments);
  }

  function downloadReport(assessment) {
    window.open(assessment.download_results, '_self')
    addNotification({title: 'Downloading results...', body: `Downloading results for ${assessment.participant.first_name} ${assessment.participant.last_name}.`})
  }

  function sendResults({id, participant}) {
    fetchApi(`/api/v1/internal/surveys/${survey.id}/assessments/${id}/send_results`, {method: 'POST'})
      .then(() => addNotification({title: 'Sending results', body: `Sending results to participant: ${participant.email}.`}))
  }

  const surveyState = parseSurveyState(survey);

  return (
    <>
      <ModalAlert headerIcon={(
        <div className="mx-auto flex h-12 w-12 p-3 items-center justify-center rounded-full bg-yellow-100 border-2 border-yellow-500">
          <HandRaisedIcon className='w-10 h-10 text-yellow-500' />
        </div>
      )} alert={modalAlert} setOpen={(open) => setModalAlert({...modalAlert, open})} open={modalAlert.open} />
      <ModalSurveyCheckout onCheckoutComplete={() => {
        loadSurvey();
        loadAssessments();
      }} purchaseQuantity={1} availableTokens={tokens.team_report} survey={survey} setOpen={(open) => setShowCheckoutModal(open)} open={showCheckoutModal} url={`/api/v1/internal/surveys/${survey.id}/checkout_team_report`} />
      <div className="pb-12">
        <div className='sm:flex sm:items-center'>
          <div className="flex justify-between items-center w-full">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Assessments</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all assessments attached to this survey.
              </p>
            </div>
          </div>

          { surveyState.canAddParticipants && (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <div>
                <button
                  onClick={(() => setShowParticipant(true))}
                  className={classNames(
                    'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto',
                    'bg-blue-600 text-white hover:bg-blue-700'
                  )}
                >
                  <UserPlusIcon className="h-5 w-5 text-white mr-2" />
                  Add Participant
                </button>
              </div>
              <ModalAddParticipant surveyId={survey.id} onClose={() => setShowParticipant(false)} open={showParticipant} onSave={createNewParticipant} />
            </div>
          ) }
        </div>
        <div className="flex items-center justify-between space-x-1 my-4">
          <div className="flex justify-between items-center">
            { surveyState.shouldLiveReload && (
              <>
                <span className="mr-1">Live</span>
                <Spinner textColor='text-black' />
              </>
            ) }
          </div>
          <div className="space-x-1 flex items-center">
            <div className="block text-sm text-right font-medium text-gray-700">Auto Send Results</div>
            <SwitchToggle onChange={(auto_send_results) => updateSurvey({ auto_send_results })} defaultChecked={survey.auto_send_results} />
          </div>
        </div>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                  <tr>
                    <AdminRequired>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        ID
                      </th>
                    </AdminRequired>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      First Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Last Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Group
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    {
                      surveyState.showDetailedAssessmentsView ? (
                        <>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Invite Sent
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Reminder Sent
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Opened
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Complete?
                          </th>
                        </>
                      ) : null
                    }
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="bg-white">
                    {assessments.map((assessment, personIdx) => {
                      const {id, group_number, participant, invite_sent, link_view_count, invite_reminders_sent, disabled, completed, assessment_type} = assessment;

                      if (id === editAssessment.id) {
                        return (
                          <tr key={participant.email} className={classNames(personIdx % 2 === 0 ? undefined : 'bg-gray-50', disabled ? 'line-through' : '')}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <input className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm" onBlur={(event) => updateParticipant(participant.id, event)} type="text" name="first_name" placeholder="first name" defaultValue={participant.first_name} />
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <input className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm" onBlur={(event) => updateParticipant(participant.id, event)} type="text" name="last_name" placeholder="Last name" defaultValue={participant.last_name} />
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <input className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm" onBlur={(event) => updateParticipant(participant.id, event)} type="email" name="email" placeholder="Email address" defaultValue={participant.email} />
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <input className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm" onBlur={(event) => updateAssessment(id, 'group_number', event)} type="number" name="group_number" placeholder="Group number" defaultValue={group_number} />
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <div>
                                <select
                                  onBlur={(event) => updateAssessment(id, 'assessment_type', event)}
                                  id="assessment_type"
                                  name="assessment_type"
                                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                  defaultValue={assessment_type}
                                >
                                  <option value='employee'>Employee</option>
                                  <option value='leader'>Leader</option>
                                </select>
                              </div>
                            </td>
                            <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a onClick={() => setEditAssessment({})} className="cursor-pointer text-blue-600 hover:text-blue-900">
                                Save
                              </a>
                            </td>
                          </tr>
                        )
                      }

                      return (
                        <tr key={participant.email} className={classNames(personIdx % 2 === 0 ? undefined : 'bg-gray-50', disabled ? 'line-through' : '')}>
                          <AdminRequired>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {assessment.id}
                            </td>
                          </AdminRequired>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div className="flex items-center">
                              {
                                assessment['paid?'] && (
                                  !!assessment.token
                                  ?
                                  (
                                    <Tippy content="Token purchase">
                                      <div>
                                        <CoinsIcon color="#FBCC14" className="mr-2 h-4 w-4" />
                                      </div>
                                    </Tippy>
                                  )
                                  :
                                  (
                                    <Tippy content="Cash purchase">
                                      <BanknotesIcon className="text-green-500 mr-2 h-4 w-4" />
                                    </Tippy>
                                  )
                                )
                              }
                              {participant.first_name}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{participant.last_name}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">{participant.email}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{group_number}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{assessment_type}</td>
                          {
                            surveyState.showDetailedAssessmentsView && (
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invite_sent} ago</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invite_reminders_sent}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{link_view_count}</td>
                                <td className={classNames('whitespace-nowrap px-3 py-4 text-sm', !completed ? 'text-gray-500' : 'text-green-500')}>
                                  {completed ? 'Yes' : 'No'}
                                </td>
                              </>
                            )
                          }

                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Menu as="div" className="relative inline-block text-left">
                              <div>
                                <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item disabled={!surveyState.canEditParticipant}>
                                      {({ active, disabled}) => (
                                        <div
                                          onClick={() => setEditAssessment(assessment)}
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
                                            'block px-4 py-2 text-sm flex space-x-2 items-center'
                                          )}
                                        >
                                          <PencilSquareIcon className="h-5 w-5 text-gray-900" />
                                          <span>Edit</span>
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item disabled={!surveyState.canResendInvitation || completed}>
                                      {({ active, disabled }) => (
                                        <div
                                          onClick={() => resentInvitationEmail(id)}
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
                                            'block px-4 py-2 text-sm flex space-x-2 items-center'
                                          )}
                                        >
                                          <ArrowDownCircleIcon className="h-5 w-5 text-gray-900" />
                                          <span>Resend invite email</span>
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item disabled={!completed || !surveyState.resultDownloadsEnabled}>
                                      {({ active, disabled }) => (
                                        <div
                                          onClick={() => downloadReport(assessment)}
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
                                            'block px-4 py-2 text-sm flex space-x-2 items-center'
                                          )}
                                        >
                                          <ArrowDownTrayIcon className="h-5 w-5 text-gray-900" />
                                          <span>Download Results</span>
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item disabled={!completed || !surveyState.resultDownloadsEnabled}>
                                      {({ active, disabled }) => (
                                        <div
                                          onClick={() => completed && sendResults(assessment) }
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
                                            'block px-4 py-2 text-sm flex space-x-2 items-center'
                                          )}
                                        >
                                          <PaperAirplaneIcon className="h-5 w-5 text-gray-900" />
                                          <span>Send Results</span>
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item disabled={(!surveyState.canRemoveAssessment && !surveyState.canDisableAssessment) || completed}>
                                      {({ active, optionDisabled }) => (
                                        <div
                                          onClick={() => surveyState.canDisableAssessment ? disableAssessment(assessment) : removeAssessment(assessment) }
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            !optionDisabled ? 'cursor-pointer' : 'cursor-not-allowed',
                                            'block px-4 py-2 text-sm flex space-x-2 items-center'
                                          )}
                                        >
                                          <NoSymbolIcon className="h-5 w-5 text-gray-900" />
                                          <span>{ surveyState.canDisableAssessment ? disabled ? 'Enable' : 'Disable' : 'Remove' }</span>
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <AdminRequired>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <div
                                            onClick={() => {
                                              navigator.clipboard.writeText(assessment.assessment_link).then(() => {
                                                addNotification({title: 'Copied', body: 'Assessment link copied to clipboard.'})
                                              })
                                            }}
                                            className={classNames(
                                              active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
                                              'block px-4 py-2 text-sm flex space-x-2 items-center'
                                            )}
                                          >
                                            <LinkIcon className="h-5 w-5 text-gray-900" />
                                            <div>
                                              <div>Copy Assessment Link</div>
                                              <div className="text-gray-300 text-xs">Admin only</div>
                                            </div>
                                          </div>
                                        )}
                                      </Menu.Item>
                                    </AdminRequired>
                                    {
                                      assessment.is_pointerpro && (
                                        <AdminRequired>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                onClick={() => syncAssessment(assessment)}
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm flex space-x-2 items-center'
                                                )}
                                              >
                                                <ArrowPathIcon className="h-5 w-5 text-gray-900" />
                                                <div>
                                                  <div>Sync w/ PointerPro</div>
                                                  <div className="text-gray-300 text-xs">Admin only</div>
                                                </div>
                                              </div>
                                            )}
                                          </Menu.Item>
                                        </AdminRequired>
                                      )
                                    }
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
