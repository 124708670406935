import React, {useEffect, useState} from 'react';

export default function SurveyAssessmentsTable(props) {
  const {csvRows, currentSurveyRows} = props;

  console.log(csvRows, currentSurveyRows);

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(csvRows.reduce((acc, row) => {
      const [_firstName, _lastName, email, group, type] = row;
      console.log(acc, acc[group])
      if (!group) return acc;
      acc[group] = acc[group] || {leaders: {}, employees: {}, raw: []};
      acc[group][type][email] = row;
      acc[group]['raw'].push(row);
      return acc;
    }, {}));
  }, [csvRows]);

  function renderRows(rows) {
    return (
      <div className="divide-y">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    First Name
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Last Name
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Group
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                { rows.map((row) => {
                  return (
                    <tr key={`csv_row_${row[2]}`}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                        {row[0]}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{row[1]}</td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{row[2]}</td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{row[3]}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (csvRows.length && currentSurveyRows.length) return ('You can only provide CSV data or Current Survey Data');

  const groups = Object.keys(data);

  // return (<pre>{JSON.stringify(data, null, 2)}</pre>)

  return (
    <div>
      {
        groups.map((group) => (
          <>
            <h2>Group {group}</h2>
            {renderRows(data[group].raw)}
          </>
        ))
      }
    </div>
  )
}
