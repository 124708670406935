import React, {useState} from 'react';
import {parseSurveyState} from '../utils/state';
import {fetchApi} from '../utils/fetch';
import {useTimer} from 'use-timer';

export const SurveyContext = React.createContext({
  survey: {},
  surveyState: {},
});

export function useSurvey() {
  return React.useContext(SurveyContext);
}

export default function SurveyProvider({children, survey: defaultSurvey}) {
  const [survey, setSurvey] = useState(defaultSurvey);

  const { pause: pauseSurveyWatcher } = useTimer({
    interval: 10000,
    autostart: true,
    onTimeUpdate: () => {
      if (surveyState.showCountdown) {
        getSurvey().then((survey) => {
          const {hasStarted} = parseSurveyState(survey);
          if (hasStarted) pauseSurveyWatcher();
          setSurvey(survey);
        });
      }
    },
  });

  const surveyState = parseSurveyState(survey);

  function getSurvey() {
    return fetchApi(`/api/v1/internal/surveys/${survey.id}`)
  }

  function loadSurvey() {
    return getSurvey().then(setSurvey);
  }

  function updateSurvey(updates) {
    return fetchApi(`/api/v1/internal/surveys/${survey.id}`, {method: 'PATCH', body: JSON.stringify({survey: updates})})
      .then(loadSurvey);
  }

  return (
    <SurveyContext.Provider value={{survey, surveyState, loadSurvey, updateSurvey}}>
      {children}
    </SurveyContext.Provider>
  );
}

