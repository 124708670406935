import React, {useState} from "react";
import {useLoaderData} from "react-router";
import {useNotifications} from "./contexts/ContextNotification";
import {NOTIFICATION_TYPES} from "./Notifications";
import {fetchApi} from "./utils/fetch";
import {CalendarDaysIcon} from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  CurrencyDollarIcon,
  DocumentMagnifyingGlassIcon,
  UserIcon
} from "@heroicons/react/24/outline";
import CoinsIcon from "./icons/CoinsIcon";
import {Spinner} from "./Spinner";
import Pages from "./Pages";
import {classNames} from "./utils/classNames";
import Tippy from "@tippyjs/react";

export default function TeamReports() {
  const {teamReports: {team_reports: teamReports, pages: teamReportPages}} = useLoaderData();
  const {addNotification} = useNotifications();
  const [downloadingTeamReport, setDownloadingTeamReport] = useState(false);

  function fetchTeamReport(event, teamReport, type) {
    console.log('Fetching team report')
    event.preventDefault();

    if (downloadingTeamReport) {
      addNotification({title: 'Another download in progress', type: NOTIFICATION_TYPES.WARNING, body: 'Please wait for the previous report to be downloaded.' })
      return;
    }

    setDownloadingTeamReport(teamReport.id);
    fetchApi(`/api/v1/internal/team_reports/${teamReport.id}/generate_team_report?type=${type}`)
    .then(({url}) => {
      addNotification({type: NOTIFICATION_TYPES.SUCCESS, title: 'Downloading!', body: 'Your team report is being downloaded!'})
      window.open(url, '_blank');
    })
    .catch(() => {
      addNotification({type: NOTIFICATION_TYPES.FAILURE, title: 'Could not download', body: 'We were not able to download the team report. We\'re looking into it.'})
    })
    .finally(() => setDownloadingTeamReport(null));
  }

  return (
    <>
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
        {
          teamReports.map((teamReport) => {
            const {id, payment, title, assessments, created_by, created_at, recent} = teamReport;

            return (
              <li key={`${id}_${title}`} className={classNames(recent && 'ring-4 ring-orange-500 ring-opacity-75' ,"list-none rounded-lg bg-white shadow")}>
                <div className="divide-y divide-gray-200">
                  <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">
                          {title || (<i>No title</i>)}
                        </h3>
                      </div>
                      <div className="flex items-center space-x-1 mt-1">
                        <CalendarDaysIcon className="h-4 w-4 text-gray-900"/>
                        <span
                          className="truncate text-sm text-gray-500">{new Intl.DateTimeFormat('en-US').format(new Date(created_at))}</span>
                      </div>
                      <div className="flex items-center space-x-1 mt-1">
                        <UserIcon className="h-4 w-4 text-gray-900"/>
                        <span className="truncate text-sm text-gray-500">{created_by}</span>
                      </div>
                    </div>
                    <div className="flex-col items-center">
                      <div className="flex items-center space-x-1">
                        <DocumentMagnifyingGlassIcon className="w-7 h-7"/>
                        <div className="text-3xl font-semibold tracking-tight text-gray-900">
                          {assessments.length}
                        </div>
                      </div>
                      <div className="flex justify-center space-x-1">
                        <Tippy content={`Paid with ${payment}`}>
                          <div>
                            { payment === 'cash' ? <CurrencyDollarIcon className="w-7 h-7" /> : <CoinsIcon className="w-7 h-7" />}
                          </div>
                        </Tippy>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="-mt-px flex">
                      <div className="-ml-px flex w-0 flex-1 divide-x divide-gray-200">
                        <a href="" onClick={(event) => fetchTeamReport(event, teamReport, 'leader')}
                           className="relative inline-flex w-0 flex-1 items-center justify-center pr-4 rounded-br-lg py-4 text-sm font-medium text-blue-700 hover:text-green-500">
                          <span className="mr-3">Leader</span>
                          { downloadingTeamReport === id ?
                            <Spinner textColor='text-gray-500'/> :
                            <ArrowDownTrayIcon className="h-5 w-5"/>
                          }
                        </a>
                        <a href="" onClick={(event) => fetchTeamReport(event, teamReport, 'team')}
                           className="relative inline-flex w-0 flex-1 items-center justify-center pr-4 rounded-br-lg py-4 text-sm font-medium text-blue-700 hover:text-green-500">
                          <span className="mr-3">Team</span>
                          { downloadingTeamReport === id ?
                            <Spinner textColor='text-gray-500'/> :
                            <ArrowDownTrayIcon className="h-5 w-5"/>
                          }
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )
          })
        }
      </div>
      <br />
      <Pages queryKey='trp' maxPage={teamReportPages.pages} />
    </>
  )
}
