import React, {useState} from 'react';

export default function DebugPrinter ({initShow = false, json, children}) {
  const [show, setShow] = useState(initShow);

  if (window.localStorage.app_debug !== 'true') return null;

  return (
    <div>
      <div onClick={() => setShow(!show)} className="cursor-pointer">Toggle debug</div>
      { show ? (
          <>
            <pre>
              {JSON.stringify(json, null, 2)}
            </pre>
            <hr />
            {children}
          </>
        )
        : null
      }
    </div>
  );
}
