import React, {useEffect, useState} from 'react';
import {getMe} from '../fetchers';

export const UserContext = React.createContext({account: {tokens_available: {}}});

export function useUser() {
  return React.useContext(UserContext);
}

export default function UserProvider({children, defaultUser = {account: {tokens_available: {}}}}) {
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    loadUser();
  }, []);

  function loadUser() {
    return getMe().then(setUser);
  }

  return (
    <UserContext.Provider value={{user, reloadUser: loadUser}}>
      {children}
    </UserContext.Provider>
  );
}

