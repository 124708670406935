import React, { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import {CheckCircleIcon, ExclamationCircleIcon, XCircleIcon} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {useNotifications} from './contexts/ContextNotification';

export const NOTIFICATION_TYPES = {
  WARNING: 'warning',
  SUCCESS: 'success',
  FAILURE: 'failure',
}
export default function Notifications() {
  const {notifications, removeNotification, addNotification} = useNotifications();

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 bottom-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-40"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {
            notifications.map((notification) => {
              const {type = 'success', title, body, id} = notification;

              let colors = {};
              let icon = <CheckCircleIcon className={`h-6 w-6 ${colors}`} aria-hidden="true" />;

              switch (type) {
                case 'success':
                  colors = { text: 'text-green-400' }
                  icon = <CheckCircleIcon className='h-6 w-6 text-green-400' aria-hidden="true" />
                  break;
                case 'warning':
                  colors = { text: 'text-yellow-400' }
                  icon = <ExclamationCircleIcon className='h-6 w-6 text-yellow-400' aria-hidden="true" />
                  break;
                case 'failure':
                  colors = { text: 'text-red-400' }
                  icon = <XCircleIcon className='h-6 w-6 text-red-400' aria-hidden="true" />
                  break;
              }

              return (
                <Transition
                  show={notification.show}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  key={id}
                >
                  <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          {icon}
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-medium text-gray-900">{title}</p>
                          <p className="mt-1 text-sm text-gray-500">{body}</p>
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                          <button
                            type="button"
                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => {
                              removeNotification(notification);
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              );
            })
          }
        </div>
      </div>
    </>
  )
}
