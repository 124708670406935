import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {UserPlusIcon} from '@heroicons/react/24/outline';
import {fetchApi} from './utils/fetch';
import {Spinner} from './Spinner';

export default function ModalAddParticipant({surveyId, open, onClose, onSave}) {
  const [showError, setShowError] = useState(null);
  const [loading, setLoading] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const data = {};

    new FormData(event.target).forEach((value, key) => {
      data[key] = value;
    });

    fetchApi(`/api/v1/internal/surveys/${surveyId}/assessments`, {
      method: 'POST',
      body: JSON.stringify({
        participant: {first_name: data.first_name, last_name: data.last_name, email: data.email},
        assessment: { group_number: data.group_number, type: data.type }
      }),
    }).then(({error, success}) => {
      if (!success) {
        setShowError(error);
      } else {
        onSave();
      }
    }).finally(() => setLoading(false));
  }

  function close() {
    setShowError(null);
    onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-transparent" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="ring-2 ring-gray-200 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form onSubmit={onSubmit}>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <UserPlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Add new participant
                      </Dialog.Title>
                      <div className="mt-2 space-y-2">
                        {showError && (<p className="text-left text-sm text-red-500">{showError}</p>)}
                        <div className="flex space-x-2">
                          <div>
                            <label htmlFor="first-name" className="text-left block text-sm font-medium text-gray-700">First name</label>
                            <div className="mt-1">
                              <input required type="text" name="first_name" id="first-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="last-name" className="text-left block text-sm font-medium text-gray-700">Last name</label>
                            <div className="mt-1">
                              <input required type="text" name="last_name" id="last-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="email" className="text-left block text-sm font-medium text-gray-700">Email address</label>
                          <div className="mt-1">
                            <input required id="email" name="email" type="email"  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <div>
                            <label htmlFor="last-name" className="text-left block text-sm font-medium text-gray-700">Group</label>
                            <div className="mt-1">
                              <input required type="text" name="group_number" id="last-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                            </div>
                          </div>
                          <div className="w-full">
                            <label htmlFor="type" className="text-left block text-sm font-medium text-gray-700">Assessment Type</label>
                            <div className="mt-1">
                              <select id="type" name="type" className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm">
                                <option defaultChecked>Employee</option>
                                <option>Leader</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => close()}
                    >
                      <span>Close</span>
                    </button>
                    <button
                      disabled={loading}
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      {!loading ? <UserPlusIcon className="h-5 w-5 text-white" /> : (<span className="h-5 w-5 mr-2"><Spinner /></span>)}
                      <span>Add Participant</span>
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
