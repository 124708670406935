import React, {useEffect, useState} from 'react';
import {Switch} from '@headlessui/react';
import {classNames} from './utils/classNames';

export default function SwitchToggle({disabled, onChange, description, checked, defaultChecked}) {
  const [enabled, setEnabled] = useState(defaultChecked);

  useEffect(() => {
    if (checked !== undefined) setEnabled(checked);
  }, [checked]);

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Description as="span" className="text-sm text-gray-500">
          {description}
        </Switch.Description>
      </span>
      <Switch
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={(value) => {
          onChange(value);
          setEnabled(value);
        }}
        className={classNames(
          enabled && !disabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}
