import {fetchApi} from '../utils/fetch';

export const getMe = async (signal) => fetchApi(`/api/v1/internal/users/me.json`, { signal });
export const getSurveyAssessments = async (signal, surveyId) => fetchApi(`/api/v1/internal/surveys/${surveyId}/assessments`, {signal});
export const getSurveys = async (signal, page, search='') => fetchApi(`/api/v1/internal/surveys?sp=${page || 1}&t=${search}`, {signal});
export const getTeamReportSurveySearch = async (signal, page = 1, search='', searchTarget='survey') => fetchApi(`/api/v1/internal/team_reports/search?sp=${page}&t=${search}&target=${searchTarget}&type=coachability`, {signal, method: 'POST'});
export const getTeamReports = async (signal, page) => fetchApi(`/api/v1/internal/team_reports?trp=${page || 1}`, {signal});
export const getDocuments = async (signal, page, certificationLevel = '') => fetchApi(`/api/v1/internal/documents?ctl=${certificationLevel}&drp=${page || 1}`, {signal});
export const getSurvey = async (signal, surveyId) => fetchApi(`/api/v1/internal/surveys/${surveyId}`, {signal});
export const getNewSurvey = async (signal) => fetchApi(`/api/v1/internal/surveys/new`, {signal})
export const getQuizzes = async (signal) => fetchApi(`/api/v1/internal/quizzes`, {signal});
export const getQuiz = async (signal, quizId) => fetchApi(`/api/v1/internal/quizzes/${quizId}`, {signal});
