import React, {useEffect, useState} from "react";
import {CalculatorIcon} from "@heroicons/react/24/outline";
import {ExpressionRunner} from "survey-core";

export default function QuizFunctionsEditor(props) {
  const {save, initQuiz = {}, requiredFormulas, creator, tokenValues, onFormulaError} = props;
  const [expressionValue, setExpressionValue] = useState({});
  const [tokens, setTokens] = useState({});
  const [valuedTokens, setValuedTokens] = useState(tokenValues || {});
  const [errors, setErrors] = useState({});
  const [touched, setTouch] = useState(false);

  useEffect(() => {
   const modifiedCallback = () => {
     parseQuiz();
   };

   props.creator.onModified.add(modifiedCallback);

   return () => {
     props.creator.onModified.remove(modifiedCallback);
   }
  }, []);

  useEffect(() => {
    if (!Object.keys(expressionValue).length) return;
    const errors = calculateErrors();
    if (errors && Object.values(errors).filter((e) => !!e).length && touched) {
      console.log('Returned errors', errors);
    }
  }, [valuedTokens, expressionValue]);

  // let's get things started
  useEffect(() => {
    parseQuiz();
  }, [initQuiz.updated_at]);

  useEffect(() => {
    const updatedExpressionValues = {...expressionValue};

    Object.keys(expressionValue).map((expressKey) => {
     const expression = new ExpressionRunner(expressionValue[expressKey].expression);
     updatedExpressionValues[expressKey].value = expression.run(valuedTokens);
    });

    setExpressionValue({...initQuiz.formulas, ...updatedExpressionValues});
  }, [valuedTokens]);

  // save when expressions change
  useEffect(() => touched ? save(expressionValue, valuedTokens) : undefined, [expressionValue]);

  function parseQuiz() {
    const qualifiedTokens = questionPreview(creator.JSON).reduce((acc, q) => {
      if (!q?.choices) return acc;
      acc[q.name] = q.choices.map((c) => c.value);
      return acc;
    }, {});

    setTokens(qualifiedTokens);

    const newValuedTokens = Object.keys(qualifiedTokens).reduce((acc, token) => {
      acc[token] = valuedTokens[token] || qualifiedTokens[token][0] || 1;
      return acc;
    }, {});

    setValuedTokens(newValuedTokens);
  }

  function questionPreview(quiz) {
   const previewQuiz = {...quiz};

   const questions = previewQuiz?.pages?.map((page) => {
     return page.elements;
   }) || [];

   previewQuiz.pages = [{name: "page1", elements: questions.flat()}];

   return questions.flat();
  }

  function checkForErrors(expression) {
   const runner = new ExpressionRunner(expression);

   let error;
   const missingVars = runner.getVariables().filter((v) => !tokens[v]);
   if (missingVars.length > 0) {
     error = `Missing variables found: ${missingVars.join(', ')}`;
   } else if (!isValid(expression) && expression?.length) {
     error = 'The expression is invalid.';
   } else {
     error = null;
   }

   return error;
  }

  function isValid(expression) {
   const runner = new ExpressionRunner(expression);
   return !isNaN(runner.run(valuedTokens)) && runner.canRun();
  }

  function calculateErrors(values = expressionValue) {
    const errors = requiredFormulas.reduce((acc, formulaName) => {
     acc[formulaName] = checkForErrors(values[formulaName]?.expression);
     return acc;
    }, {});

    if (Object.values(errors).filter((e) => !!e).length > 0) {
      onFormulaError(true);
      creator.tabs[3].title = 'Formulas - ERROR';
    } else {
      onFormulaError(false);
      creator.tabs[3].title = 'Formulas';
    }

    setErrors(errors);
    return errors;
  }

  return (
   <div className="mx-auto w-3/4 mt-10">
     <div>
       <div className="flex justify-between items-center">
         <div className="pb-5">
           <h3 className="text-base font-semibold leading-6 text-lg text-gray-900">Formulas</h3>
           <p className="mt-2 text-sm text-gray-500">
             Formulas are how individual Factor scores are calculated. You can use the same syntax that is supported by SurveyJS as described in their documentation.
           </p>
         </div>
       </div>
       <div className="flex justify-between mt-6 space-x-3">
         <div className="border-b border-gray-200 mb-3 w-3/4">
           Calculations
         </div>
         <div className="border-b border-gray-200 mb-3 w-1/4">
           Test Values
         </div>
       </div>
       <div className="flex justify-between space-x-3">
         <div className="w-3/4 border-t border-gray-100 bg-white px-5 rounded-md shadow-sm">
           <dl className="divide-y divide-gray-100 sm:grid sm:grid-cols-2 sm:gap-3">
             {
               requiredFormulas.map((formulaName) => {
                 return (
                   <div className="px-4 py-6 sm:px-0" key={`formula_${formulaName}`}>
                     <dt className="text-sm mb-2 flex justify-between items-center font-medium leading-6 text-gray-900">
                       <p>{formulaName}</p>
                       <p className="font-mono space-x-2 flex items-center">
                         <CalculatorIcon className="h-4 w-4" />
                         <span className="tracking-wide">{expressionValue[formulaName]?.value || 'N/A'}</span>
                       </p>
                     </dt>
                     <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                       <textarea
                         className="block w-full rounded-md font-mono border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                         placeholder="Enter an expression..."
                         rows={4}
                         defaultValue={expressionValue[formulaName]?.expression}
                         onBlur={({target: {value}}) => {
                           const runner = new ExpressionRunner(value);
                           const result = runner.run(valuedTokens);
                           const updatedExpressionValue = {...expressionValue, [formulaName]: {expression: value, value: result}};
                           const error = calculateErrors(updatedExpressionValue)[formulaName];
                           if (!error) setExpressionValue(updatedExpressionValue);
                           setTouch(true);
                         }}
                       >
                       </textarea>
                       {errors[formulaName] && (<p className="font-mono text-red-400">{errors[formulaName]}</p>)}
                     </dd>
                   </div>
                 )
               })
             }
           </dl>
         </div>
         <div className="w-1/4 border-t border-gray-100 bg-white rounded-md shadow-sm p-5 sm:grid sm:grid-cols-2 sm:gap-4">
           {
             Object.keys(tokens).map((tokenKey) => {
               const token = tokens[tokenKey];

               return (
                 <div key={`token_${tokenKey}`}>
                   <div>{tokenKey}</div>
                   <input
                     className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                     onFocus={() => setTouch(true)}
                     onChange={({target: {value}}) => {
                       setValuedTokens({...valuedTokens, [tokenKey]: value});
                     }}
                     type="number"
                     min={Math.min(...token)}
                     defaultValue={valuedTokens[tokenKey]}
                     max={Math.max(...token)}
                   />
                 </div>
               )
             })
           }
         </div>
       </div>
     </div>
   </div>
  );
}
