import React, {Fragment, useEffect, useRef, useState} from 'react';
import {fetchApi} from './utils/fetch';
import {ClockIcon} from '@heroicons/react/20/solid';
import SurveyAssessments from './SurveyAssessments';
import {
  CheckIcon, CircleStackIcon,
  Cog6ToothIcon, InformationCircleIcon,
  LockClosedIcon,
  PaperAirplaneIcon,
  PencilIcon,
  ShoppingCartIcon
} from '@heroicons/react/24/outline';
import ModalSurveyCheckout from './ModalSurveyCheckout';
import ModalAlert from './ModalAlert';
import { Menu, Transition } from '@headlessui/react';
import {classNames} from './utils/classNames';
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  DocumentDuplicateIcon, ExclamationTriangleIcon, HeartIcon, MinusIcon,
  PencilSquareIcon, TrashIcon,
  UserPlusIcon
} from '@heroicons/react/24/solid';
import {useTimer} from 'use-timer';
import ModalSendSurveyConfirmation from './ModalSendSurveyConfirmation';
import {useSurvey} from './contexts/ContextSurvey';
import ModalLoading from './ModalLoading';
import {useAssessments} from "./contexts/ContextAssessments";
import {useUser} from "./contexts/ContextUser";
import Tippy from "@tippyjs/react";
import SurveyTypeBadge from "./SurveyTypeBadge";

export default function Survey() {
  const {user} = useUser();
  const {account: {tokens_available: tokens}} = user;
  const {assessments, unpaidAssessments, loadAssessments} = useAssessments();
  const {survey, surveyState, loadSurvey, updateSurvey} = useSurvey();
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [modalAlert, setModalAlert] = useState({title: null, body: null, buttonText: 'Close', open: false});
  const [sendingInvites, setSendingInvites] = useState({open: false, msg: null});
  const [sendSurveyConfirmationModal, setSendSurveyConfirmationModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const nameInputRef = useRef(null);

  const { start: startAssessmentReloader, pause: pauseAssessmentReloader } = useTimer({
    interval: 15000,
    onTimeUpdate: loadSurvey,
  });

  useEffect(() => {
    if (survey.status === 'active') pauseAssessmentReloader();
    loadAssessments()
  }, [survey.status]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const stripeSessionId = params.get('session_id');
    const isTeamReport = params.get('team_report') === 'true';
    if (!stripeSessionId || params.get('cancel') === 'true') return;

    const uri = `checkout${isTeamReport ? '_team_report_' : '_'}confirmation`;

    fetchApi(`/api/v1/internal/surveys/${survey.id}/${uri}`, {
      method: 'POST',
      body: JSON.stringify({session_id: stripeSessionId}),
    }).then(({error}) => {
      loadSurvey();
    });
  }, []);

  function closeSurvey() {
    if (window.confirm('Are you sure you want to close this survey? Doing so will disallow anyone from completing the survey further.')) {
      fetchApi(`/api/v1/internal/surveys/${survey.id}/close`, {method: 'POST'})
        .catch(console.error)
        .finally(loadSurvey);
    }
  }

  function sendInvites() {
    setSendingInvites({open: true, loading: true, message: 'Starting the survey. Please wait...'});
    fetchApi(`/api/v1/internal/surveys/${survey.id}/send_invites`, {method: 'POST'})
      .then(({success, msg}) => {
        if (success) {
          loadSurvey();
          startAssessmentReloader();
          setSendingInvites({open: true, loading: false, message: msg, sent: success});
          setTimeout(() => setSendingInvites({open: false, message: msg, loading: false, sent: success}), 3000);
        } else {
          setSendingInvites({open: true, loading: false, message: msg, sent: success});
        }
      });
  }

  function startSurvey() {
    fetchApi(`/api/v1/internal/surveys/${survey.id}/start`, {method: 'POST'})
      .then(({error, tokens, unpaid=[]}) => {
        if (unpaid.length) {
          setShowCheckoutModal(true);
        } else {
          setSendSurveyConfirmationModal(true);
        }
      })
      .catch(console.error)
  }

  function renderStartStatus() {
    if (survey['expired?']) {
      return (
        <>
          <span>This survey is complete.</span>
        </>
      );
    }

    if (surveyState.hasStarted) {
      return (
        <>
          <span className="ml-1 rounded-full h-3 w-3 bg-green-500 mr-2 shadow-sm ring-2 ring-green-600" />
          <span>Started {survey.starts_on_in} ago</span>
        </>
      );
    } else {
      if (surveyState.hasPaid) {
        if (surveyState.isScheduledToAutoStart && surveyState.showCountdown) {
          return (
            <>
              <span className="ml-1 animate-pulse rounded-full h-3 w-3 bg-green-500 mr-2 shadow-sm ring-2 ring-green-600" />
              <span>Starting in {survey.starts_on_in}</span>
            </>
          );
        }

        return (
          <>
            <span className="ml-1 rounded-full h-3 w-3 bg-green-500 mr-2 shadow-sm ring-2 ring-green-600" />
            <span>Ready to start</span>
          </>
        );
      } else {
        return (
          <div className="ml-1 animate-pulse flex items-center space-x-1">
            <div className="ml-1 rounded-full h-3 w-3 bg-pink-500 mr-2 shadow-sm ring-2 ring-pink-600" />
            <div>Payment required</div>
          </div>
        );
      }
    }
  }

  function renderSurveyStartWarning() {
    if (surveyState.isScheduledToAutoStart && !surveyState.hasPaid) {
      return (
        <div className="rounded-md bg-pink-50 p-4 border-pink-200 border">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-pink-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-pink-800">Attention needed</h3>
              <div className="mt-2 text-sm text-pink-700">
                <p>Your survey is scheduled to start automatically in {survey.starts_on_in}. You must first checkout before the assessments can be sent.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function reclaimTokens() {
    if (window.confirm(`Are you sure you would like to reclaim your ${survey.used_tokens} used tokens? This is only possible before you've purchased the rest of the assessments at checkout.`))
    fetchApi(`/api/v1/internal/surveys/${survey.id}/reclaim`, {method: 'PATCH'})
        .then(({error, msg, success}) => {
          if (success) {
            loadSurvey();
            loadAssessments();
          } else {
            window.alert('Something went wrong :(');
          }
        })
        .catch(console.error)
  }

  return (
    <>
      <ModalSendSurveyConfirmation survey={survey} open={sendSurveyConfirmationModal} onUpdate={updateSurvey} onConfirm={sendInvites} setOpen={(open) => setSendSurveyConfirmationModal(open)} />
      <ModalAlert alert={modalAlert} open={modalAlert.open} setOpen={(open) => setModalAlert({...modalAlert, open})} />
      <ModalLoading alert={sendingInvites} open={sendingInvites.open} setOpen={(open) => setSendingInvites({...sendingInvites, open})} headerIcon={(
        <div className={classNames('transition-all mx-auto flex h-12 w-12 items-center justify-center rounded-full', sendingInvites.sent ? 'bg-green-100' : 'bg-gray-100')}>
          { sendingInvites.sent && <PaperAirplaneIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>}
          { !sendingInvites.sent && sendingInvites.loading && <MinusIcon className="animate-spin h-6 w-6 text-gray-600" aria-hidden="true"/>}
          { !sendingInvites.sent && !sendingInvites.loading && <InformationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>}
        </div>
      )} />
      <ModalSurveyCheckout survey={survey} availableTokens={tokens.assessment} purchaseQuantity={unpaidAssessments.length} setOpen={(open) => setShowCheckoutModal(open)} open={showCheckoutModal} />
      <div className="px-4 sm:px-6 lg:px-8 space-y-5 mt-5">
        { renderSurveyStartWarning() }
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            { !editName ? (
              <h2 className="flex items-center space-x-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                <span>{survey.name}</span>
                <PencilIcon className="h-6 w-6 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => setEditName(true)} />
              </h2>
            ) : (
              <div className="flex items-center space-x-2">
                <input type="text" defaultValue={survey.name} className="bg-transparent border-none text-2xl p-0" ref={nameInputRef} />
                <CheckIcon className="h-7 w-7 text-gray-800 hover:text-green-700 cursor-pointer" onClick={() => updateSurvey({ name: nameInputRef.current.value }).then(() => setEditName(false))} />
              </div>
            )}
            <div className="mb-3 my-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                { renderStartStatus() }
              </div>
              { survey['started?'] && !survey['expired?'] && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400" />
                  <span>Ends in {survey['ends_on_in']}</span>
                </div>
              )}
            </div>
            <Tippy content={'The type of survey.'} placement='right'>
              <div className="inline"><SurveyTypeBadge survey={survey}/></div>
            </Tippy>
          </div>
          <div className="mt-2 flex justify-between lg:mt-0 lg:ml-4">
            { surveyState.canStartSurvey && (
              <span className="sm:ml-3">
                <button onClick={startSurvey} type="button" className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  <PaperAirplaneIcon className="-ml-1 mr-2 h-5 w-5" />
                  Start Survey
                </button>
              </span>
            )}

            { surveyState.mustCheckoutSurvey && (
              <span className="sm:ml-3">
                <button onClick={startSurvey} type="button" className="inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                  <ShoppingCartIcon className="-ml-1 mr-2 h-5 w-5" />
                  Checkout
                </button>
                {
                  surveyState.hasUsedTokens && (
                    <button onClick={reclaimTokens} type="button" className="ml-2 inline-flex items-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2">
                      <CircleStackIcon className="-ml-1 mr-2 h-5 w-5" />
                      Reclaim Tokens
                    </button>
                  )
                }
              </span>
            )}

            { surveyState.isPaymentPending && (
              <span className="sm:ml-3">
                <button onClick={startSurvey} type="button" className="inline-flex items-center rounded-md border pointer-not-allowed border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                  <MinusIcon className="animate-spin -ml-1 mr-2 h-5 w-5" />
                  Pending confirmation...
                </button>
              </span>
            )}

            { surveyState.canCloseSurvey && (
              <span className="sm:ml-3">
                <button onClick={closeSurvey} type="button" className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  <LockClosedIcon className="-ml-1 mr-2 h-5 w-5" />
                  Close Survey
                </button>
              </span>
            )}

            <span className="sm:ml-3 hidden">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <Cog6ToothIcon className="h-5 w-5 text-gray-700" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <PencilSquareIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Edit
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <DocumentDuplicateIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Duplicate
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <ArchiveBoxIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Archive
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <ArrowRightCircleIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Move
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <UserPlusIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Share
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <HeartIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Add to favorites
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Delete
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </span>
          </div>
        </div>
        <SurveyAssessments survey={survey} assessments={assessments} liveReload={surveyState.shouldLiveReload} />
      </div>
    </>
  )
}
