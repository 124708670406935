import React from 'react';
import {Spinner} from './Spinner';

export function Button({color='blue', onClick, additionalClasses, className, disabled, loading = false, children}) {
  return (
    <div
      role="button"
      className={className || `${additionalClasses} transition-all inline-flex w-full items-center justify-center rounded-md border border-transparent ${loading || disabled ? `bg-gray-400 cursor-not-allowed` : `cursor-pointer hover:bg-${color}-700 bg-${color}-600`} px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2 sm:text-sm`}
      onClick={() => !disabled && onClick()}
    >
      {loading && <div className="animate-spin mr-2 w-5 h-5"><Spinner /></div>}
      {children}
    </div>
  )
}
