import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {CheckIcon, NewspaperIcon} from '@heroicons/react/24/outline';
import {useLoaderData} from "react-router";
import {classNames} from './utils/classNames';
import SwitchToggle from "./SwitchToggle";

export default function ModalQuizSettings({onConfirm, quiz = {}, open = false, setOpen = () => {} }) {
  const {coachability_report_contents: coachabilityReportContents, report_contents_type: reportContentsType} = useLoaderData();
  const [isAvailable, setIsAvailable] = useState(quiz.is_available || false);
  const [isForFeedbackOnly, setIsForFeedbackOnly] = useState(quiz.is_for_feedback_only || false);
  const [isCoachabilityQuiz, setIsCoachabilityQuiz] = useState(quiz.is_coachability || false);
  const [isInternal, setIsInternal] = useState(quiz.is_internal);
  const [description, setDescription] = useState(quiz.description || null);
  const [surveyName, setSurveyName] = useState(quiz.name || null);
  const [surveyType, setSurveyType] = useState(quiz.type_name || null);
  const [reportTypeId, setReportTypeId] = useState(null);
  const [error, setError] = useState(null);

  function create() {
    if (!editMode && !window.confirm('Are you certain of your settings? Some cannot be changed after creation.')) return;

    const savePromise = onConfirm({
      surveyName: surveyName,
      surveyType,
      isAvailable,
      isInternal,
      isCoachabilityQuiz,
      isForFeedbackOnly,
      description,
      reportTypeId,
    });

    if (savePromise) {
      savePromise.then(({success, error}) => {
        if (error) return setError(error);
        setOpen(false);
      })
    } else {
      setOpen(false);
    }
  }

  const editMode = !!quiz.type_name;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  {/*<pre>{JSON.stringify({ description, surveyType, surveyName }, null, 2)}</pre>*/}
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 ring-2 ring-green-500">
                    <NewspaperIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      {editMode ? `Edit the ${quiz.type_name} quiz` : "Create a new Quiz"}
                    </Dialog.Title>

                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Give this quiz a name</h2>
                      <div className="text-sm text-gray-500">A name must be unique and cannot be changed once created.</div>
                      <input defaultValue={quiz.name} disabled={quiz.name} placeholder="CCI Coachability" onChange={({target: {value}}) => setSurveyName(value)} type="text" name="survey_name" id="survey_name" className={`${quiz.name ? 'text-gray-500' : 'text-gray-900'} block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 mt-4`} />
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Give this quiz a type</h2>
                      <div className="text-sm text-gray-500">A type must be unique and cannot be changed once created.</div>
                      <input defaultValue={quiz.type_name} disabled={quiz.type_name} placeholder="coachability, behavioral_leader, etc" onChange={({target: {value}}) => setSurveyType(value)} type="text" name="survey_name" id="survey_name" className={`${quiz.type_name ? 'text-gray-500' : 'text-gray-900'} block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6 mt-4`} />
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Description</h2>
                      <div className="text-sm text-gray-500">This description will be visible to users selecting their survey type.</div>
                      <textarea defaultValue={quiz.description} onChange={({target: {value}}) => setDescription(value)} className="mt-5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:py-1.5 sm:text-sm sm:leading-6"></textarea>
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Is this quiz a coachability quiz?</h2>
                      <SwitchToggle disabled={editMode || isForFeedbackOnly} checked={isCoachabilityQuiz} defaultChecked={isCoachabilityQuiz} description={'This can only be set during the creation of a new quiz type.'} onChange={(value) => {
                        setIsCoachabilityQuiz(value);
                      }} />
                    </div>
                    {
                      isCoachabilityQuiz && !editMode ? (
                        <>
                          <hr className="mt-5" />
                          <div className="mt-4">
                            <h2 className="text-left text-sm font-medium text-gray-900">Link a Coachability Report</h2>
                            <select
                              id="report_contents"
                              name="report_contents"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              onChange={({target: {value}}) => setReportTypeId(value)}
                            >
                              <option disabled selected>Choose a report type</option>
                              {
                                coachabilityReportContents.map(report => {
                                  return <option key={`${report.type_name}_${report.id}`} value={report.id}>{report.type_name}</option>
                                })
                              }
                            </select>
                          </div>
                        </>
                      ) : null
                    }
                    {isCoachabilityQuiz && editMode ? (
                      <>
                        <hr className="mt-5" />
                        <div className="mt-5 flex justify-between items-center">
                          <h2 className="text-left text-sm font-medium text-gray-900">Report Content Type</h2>
                          <h2 className="text-left text-sm font-medium text-gray-900">{isCoachabilityQuiz && editMode ? reportContentsType : null}</h2>
                        </div>
                      </>
                    ) : null}
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Is this quiz for feedback only?</h2>
                      <SwitchToggle disabled={editMode} defaultChecked={isForFeedbackOnly} description={!editMode ? 'This can only be set on type creation' : 'This cannot be changed'} onChange={(value) => {
                        setIsForFeedbackOnly(value);
                        setIsAvailable(value);
                        if (isCoachabilityQuiz && !editMode && value) setIsCoachabilityQuiz(false);
                      }} />
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Is this quiz available for use?</h2>
                      <SwitchToggle disabled={!editMode || isForFeedbackOnly} defaultChecked={isAvailable} description={editMode ? 'This can be set inside the quiz editor.' : 'Set the visibility of this survey'} onChange={(value) => {
                        setIsAvailable(value);
                      }} />
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Internal Only?</h2>
                      <SwitchToggle defaultChecked={isInternal} description={'Internal quizzes can only be used by Coachability Consultants'} onChange={(value) => {
                        setIsInternal(value);
                      }} />
                    </div>
                  </div>
                </div>
                {error && (<p className="text-sm text-center mt-5 text-red-500 fond-bold">{error}</p>)}
                <hr className="mt-5" />
                <div className="mt-5 sm:mt-6 flex justify-between space-x-2">
                  <button
                    type="button"
                    className="inline-flex w-1/4 justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex w-1/3 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm sm:text-sm',
                      true ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2' : 'cursor-not-allowed'
                    )}
                    onClick={create}
                  >
                    <CheckIcon className="h-5 w-5 text-white mr-2" />
                    <span>Save & Edit</span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
