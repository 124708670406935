export function parseSurveyState(survey) {
  const hasStarted = survey['started?'];
  const hasPaid = survey['all_paid?'];
  const hasClosed = survey['expired?'];
  const isPaymentPending = survey.status === 'payment_pending';

  return {
    hasPaid,
    hasStarted,
    hasClosed,
    isPaymentPending,
    showCountdown: (survey.starts_on_in_minutes < 60 && survey.starts_on_in_minutes > -1) || isPaymentPending,
    canEditParticipant: !hasStarted && !hasClosed,
    canResendInvitation: hasStarted,
    canRemoveAssessment: !hasStarted && !hasClosed,
    canDisableAssessment: hasStarted,
    canAddParticipants: !hasPaid && !hasStarted && !hasClosed,
    mustCheckoutSurvey: !hasPaid && !hasClosed && !isPaymentPending,
    canStartSurvey: hasPaid && !hasStarted && !hasClosed,
    showDetailedAssessmentsView: hasStarted || hasClosed,
    isScheduledToAutoStart: survey['starts_on'] && !hasStarted && !hasClosed && survey.starts_on_in_minutes > -1,
    canCloseSurvey: hasStarted && !hasClosed,
    shouldLiveReload: hasStarted && !hasClosed,
    hasPurchasedTeamReport: survey.team_report?.purchased,
    hasGeneratedTeamReport: survey.team_report?.status === 'paid',
    hasAttachedTeamReport: survey.team_report?.attached,
    hasUsedTokens: survey.used_tokens > 0,
    resultDownloadsEnabled: survey.is_coachability,
  }
}
