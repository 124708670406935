import Tippy from "@tippyjs/react";
import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import {HandRaisedIcon, InformationCircleIcon, PaperAirplaneIcon} from '@heroicons/react/24/outline';
import InputDateTime from "./InputDateTime";
import SurveyEndsOnRange from './SurveyEndsOnRange';
import {useSurvey} from './contexts/ContextSurvey';
import {classNames} from './utils/classNames';
import SwitchToggle from './SwitchToggle';

export default function ModalSendSurveyConfirmation({onConfirm, onUpdate, open = false, setOpen = () => {} }) {
  const {survey} = useSurvey();

  function onSetEndsOnRange(value) {
    onUpdate({ends_on_range: value});
  }

  function onSetDeadline(value) {
    onUpdate({deadline: value});
  }

  function onSetAutoSendResults(value) {
    onUpdate({auto_send_results: value});
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100 ring-2 ring-yellow-500">
                    <HandRaisedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      Are you ready?
                    </Dialog.Title>
                    <div className="mt-2 space-y-2">
                      <p className="text-sm text-gray-700">
                        You're about to start the survey. After you start the survey you will NOT be able to add any participants or edit any of their details.
                      </p>
                      <p className="text-sm text-gray-700">
                        Are you sure you would like to start?
                      </p>
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Select a time range to end the survey</h2>
                      <SurveyEndsOnRange onChange={onSetEndsOnRange} />
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <div className="flex items-center space-x-2">
                        <h2 className="text-left text-sm font-medium text-gray-900">Set a deadline (Eastern)</h2>
                        <Tippy content="This will add copy to the assessment invitation email noting the deadline. This is not required." placement="top-start">
                          <InformationCircleIcon className="text-gray-500 h-5 w-5" />
                        </Tippy>
                      </div>
                      <InputDateTime type="datetime-local" defaultValue={survey.deadline} onChange={({target: {value}}) => onSetDeadline(value)} />
                    </div>
                    <hr className="mt-5" />
                    <div className="mt-4">
                      <h2 className="text-left text-sm font-medium text-gray-900">Automatically send results</h2>
                      <SwitchToggle defaultChecked={survey.auto_send_results} description="On completion, an email with results will be sent." onChange={onSetAutoSendResults} />
                    </div>
                  </div>
                </div>
                <hr className="mt-5" />
                <div className="mt-5 sm:mt-6 flex justify-between space-x-2">
                  <button
                    type="button"
                    className="inline-flex w-1/3 justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm sm:text-sm',
                      survey.ends_on_range ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2' : 'cursor-not-allowed'
                    )}
                    onClick={() => {
                      if (survey.ends_on_range) {
                        setOpen(false);
                        onConfirm();
                      }
                    }}
                  >
                    <PaperAirplaneIcon className="h-5 w-5 text-white mr-2" />
                    <span>Save and Send</span>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
