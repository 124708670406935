import React, {useEffect, useMemo} from 'react';

export default function DateFormatter({dateString}) {
  return useMemo(() => {
    return new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: 'numeric',
      minute: 'numeric',
    })
    .format(new Date(dateString));
  }, []);
}